import Switch from 'components/switch';
import { BusinessReport } from 'interfaces/business/BusinessReport';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MdFileUpload } from 'react-icons/md';
import DropZonefile2 from 'views/admin/main/ecommerce/settingsProduct/components/DropZonefile2';
import googleSheets from 'assets/img/integrations/google-sheets.png';
import { BusinessReportGoogleSheets } from 'interfaces/business/BusinessReportGoogleSheets';
import GoogleSheetModal from './GoogleSheetModal';
import { GoogleOAurhToken } from 'interfaces/oauth/GoogleOAuthToken';
import { BusinessReportIntegrationItem } from 'interfaces/business/BusinessReportIntegration';
import TinyMCE from 'components/tinymce';
import defaultChart from 'assets/img/crm/defaultChart.png';
import useGoogleAuth from 'services/google/googleAuth';

interface IntegrationCardProps {
  label: string;
  descriptionText?: string;
  field: string;
  integrationField: string;
  report: BusinessReport;
  setReport: Dispatch<SetStateAction<BusinessReport>>;
  googleOAuthToken: GoogleOAurhToken | null;
  setGoogleOAuthToken?: Dispatch<SetStateAction<GoogleOAurhToken>>;
  googleSheet: BusinessReportGoogleSheets | null;
  setGoogleSheet: Dispatch<SetStateAction<BusinessReportGoogleSheets>>;
  setMedia: Dispatch<SetStateAction<File>>;
  refer?: any;
  init?: string;
  defaultContent?: boolean;
  chart?: File;
  addBottomLine?: boolean;
}

const IntegrationCard = ({
  label,
  descriptionText,
  field,
  integrationField,
  report,
  chart,
  setReport,
  googleOAuthToken,
  setGoogleOAuthToken,
  googleSheet,
  setGoogleSheet,
  setMedia,
  refer,
  init,
  defaultContent,
  addBottomLine,
}: IntegrationCardProps) => {
  const [googleSheetModal, setGoogleSheetModal] = useState<boolean>(false);
  const [isChartVisible, setIsChartVisible] = useState(true);

  const { googleLogin } = useGoogleAuth({
    setToken: setGoogleOAuthToken,
    handleSuccess: () => setGoogleSheetModal(true),
  });

  const handleRemoveChart = () => {
    setIsChartVisible(false);
  };

  const handleSwitch = () => {
    setReport({ ...report, [field]: !report[field as keyof typeof report] });
  };

  const getFlagValue = () => {
    return report[field as keyof typeof report];
  };

  const getIntegrationValue = (): BusinessReportIntegrationItem => {
    return (
      (report[
        integrationField as keyof typeof report
      ] as BusinessReportIntegrationItem) ?? {}
    );
  };

  const googleSheetsConfigureHandle = () => {
    if (googleOAuthToken) {
      setGoogleSheetModal(!googleSheetModal);
    } else {
      googleLogin();
    }
  };

  const removeMedia = () => {
    if (getIntegrationValue().mediaObject) {
      let integration = getIntegrationValue();
      integration.mediaObject = null;

      setReport({ ...report, [integrationField]: integration });
    }
  };

  const removeIntegration = () => {
    let integration = getIntegrationValue();

    if (integration.googleSheets) {
      integration.googleSheets = null;

      setReport({ ...report, [integrationField]: integration });
      setGoogleSheet(null);
    }
  };

  const handleUpdateMediaDescription = (event: any) => {
    let integration = getIntegrationValue();
    integration.mediaObjectDescription = event.target.value;

    setReport({ ...report, [integrationField]: integration });
  };

  const showNewCustomersMedia = () => {
    return (
      <div className="border-sky-500 ml-2 mr-4 box-content w-[92px] rounded-2xl border-2 border-dotted px-6 pb-[11px] pt-[18px]">
        <div className="mt-2 box-border flex items-center justify-center rounded-xl py-2 align-top dark:!border-navy-700 lg:pb-0">
          <img src={defaultChart} style={{ width: 60 }} alt="Default Logo" />
        </div>
        <div className="text-center">
          <button onClick={() => handleRemoveChart()}>[remove]</button>
        </div>
      </div>
    );
  };

  const showGoogleSheetDetails = () => {
    if (googleSheet) {
      return (
        <div className="overflow-hidden">
          <p className="overflow-hidden text-ellipsis text-[10px]">
            SheetId: {googleSheet.spreadsheetId.substring(0, 10)}{' '}
          </p>
          <p className="overflow-hidden text-ellipsis text-[10px]">
            Workspace: {googleSheet.worksheetId}
          </p>
          <p className="mb-0.5 overflow-hidden text-ellipsis text-[10px]">
            Column: {googleSheet.column}
          </p>
          <div className="text-center ">
            <button onClick={removeIntegration}>[remove]</button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="mt-5">
      <div className="flex items-center">
        <Switch color="blue" checked={getFlagValue()} onChange={handleSwitch} />
        <p className="ml-2 font-bold text-blue-900 dark:text-white">{label}</p>
      </div>
      <div className="ml-2 mt-3">
        <p>{descriptionText}</p>
      </div>
      <div
        className={` duration-900 transition-all ${
          getFlagValue() ? 'opacity-100' : 'hidden'
        }`}
      >
        <div className="mt-5 flex items-baseline">
          <DropZonefile2
            disabled={!!getFlagValue()}
            setFile={setMedia}
            description={true}
            media={
              getIntegrationValue() && typeof getIntegrationValue() !== 'string'
                ? getIntegrationValue().mediaObject
                : ''
            }
            handleRemove={removeMedia}
            handleUpdateDescription={handleUpdateMediaDescription}
            valueDesciption={getIntegrationValue()?.mediaObjectDescription}
            content={
              <div className="border-sky-500 mr-2 box-content w-[101.5px] rounded-2xl   border-2 border-dotted p-3 px-6">
                <div className="box-border flex items-center justify-center rounded-xl py-2 dark:!border-navy-700 lg:pb-0">
                  <MdFileUpload className="text-[40px]  text-blue-900 dark:text-white" />
                </div>
                <h4 className="ml-2 text-sm font-bold text-blue-900 dark:text-white">
                  Upload Image
                </h4>
              </div>
            }
          />
          {defaultContent &&
          isChartVisible &&
          chart === undefined &&
          report.newCustomersIntegration === undefined ? (
            <div className="border-sky-500 ml-2 mr-4 box-content w-[92px] rounded-2xl border-2 border-dotted px-6 pb-[11px] pt-[18px]">
              <div className="mt-2 box-border flex items-center justify-center rounded-xl py-2 align-top dark:!border-navy-700 lg:pb-0">
                <img
                  src={defaultChart}
                  style={{ width: 60 }}
                  alt="Default Logo"
                />
              </div>
              <div className="text-center">
                <button onClick={() => handleRemoveChart()}>[remove]</button>
              </div>
            </div>
          ) : defaultContent && report.newCustomersIntegration !== undefined ? (
            <div
              className={`  ${
                report.newCustomersIntegration.mediaObject === undefined
                  ? ' '
                  : 'hidden'
              }`}
            >
              <>{showNewCustomersMedia()}</>
            </div>
          ) : (
            ''
          )}
          <div className="border-sky-500 mt-2 rounded-2xl border-2 border-dotted px-6 pb-[14px] pt-4">
            <div className="mt-1 flex items-center justify-center">
              <img
                style={{ width: 40 }}
                alt=""
                src={googleSheets}
                onClick={googleSheetsConfigureHandle}
              />
            </div>
            <p className="ml-2 text-sm font-bold text-blue-900 dark:text-white">
              Insert Graph
            </p>
          </div>
          <div
            className={`ml-4 mt-2  flex self-center px-6 pb-4 pt-3  ${
              googleSheet !== null
                ? 'border-sky-500 w-40 rounded-2xl  border-2 border-dotted '
                : 'hidden'
            }`}
          >
            <>{showGoogleSheetDetails()}</>
          </div>
        </div>
        <div className="mt-5">
          <TinyMCE disable={false} init={init} refer={refer} />
        </div>
      </div>
      <GoogleSheetModal
        open={googleSheetModal}
        googleOAuthToken={googleOAuthToken}
        googleSheet={
          typeof getIntegrationValue() !== 'string' &&
          getIntegrationValue() !== undefined &&
          getIntegrationValue().googleSheets !== undefined &&
          typeof getIntegrationValue().googleSheets !== 'string'
            ? getIntegrationValue().googleSheets
            : googleSheet
        }
        setGoogleSheet={setGoogleSheet}
      />
      {addBottomLine ? (
        <hr className="mb-2 mr-2 mt-7 h-[2px] bg-blue-900" />
      ) : (
        ''
      )}
    </div>
  );
};

export default IntegrationCard;
