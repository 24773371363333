import axios from 'axios';
import getViolations from 'components/form/error/getViolations';
import {
  ReportAssistantFormIntefrace,
  ReportAssistantIntefrace,
} from 'interfaces/business/ReportAssistant';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from 'components/spinner/Spinner';
import AssistantForm from './components/AssistantForm';
import { BusinessReport } from 'interfaces/business/BusinessReport';
import { ExplainerText } from '../../../../components/explainerText/explainerText';

const ReportAssistant = () => {
  const { businessId } = useParams();
  const [reportAssistant, setReportAssistant] =
    useState<ReportAssistantIntefrace>();
  const [errorReportAssistant, setErrorReportAssistant] =
    useState<ReportAssistantFormIntefrace>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const submitHandle = async () => {
    setLoading(true);

    const url = process.env.REACT_APP_API_URL + '/business_reports/assistant';

    axios
      .post(url, reportAssistant)
      .then((r) => {
        const today = new Date();
        const month = today.toLocaleString('default', { month: 'long' });

        const newReport: BusinessReport = {
          name: month + ' Update Report',
          commentaryText: r.data.openingCommentary,
          commentary: true,
          highlightsText: r.data.highlights,
          highlights: true,
          lowlightsText: r.data.lowlights,
          lowlights: true,
          productUpdatesText: r.data.productUpdate,
          productUpdates: true,
          logo: true,
          newCustomers: true,
          business: '/businesses/' + businessId,
        };

        setLoading(false);
        handleSaveReport(newReport);
      })
      .catch((e) => {
        setLoading(false);
        alert('Something went wrong... please refresh the page');
      });
  };

  const handleSaveReport = async (report: BusinessReport) => {
    setLoading(true);
    const url = process.env.REACT_APP_API_URL + '/business_reports';

    await axios
      .post(url, report)
      .then((r) => {
        navigate(
          '/business/:businessId/report/:reportId/assistant'
            .replace(':businessId', businessId)
            .replace(':reportId', r.data.id)
        );
      })
      .catch((e) => {
        setLoading(false);
        alert('Something went wrong... please refresh the page');
      });
  };

  const displaySpinner = () => {
    if (loading) {
      return (
        <div className="relative">
          <div className="fixed left-1/3 top-1/4 z-50 -translate-x-1/3 -translate-y-1/3 transform">
            <div className="absolute z-50 mb-5 grid h-full w-full grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-2">
              <Spinner />
            </div>
          </div>
        </div>
      );
    }
  };
  useEffect(() => {
    setReportAssistant({
      ...reportAssistant,
      business: 'businesses/' + businessId,
      highlights: '',
      updates: '',
      lowlights: '',
      promptOpeningCommentary:
        'Write an Opening Commentary section for an Investor Update from the submitted content sections Highlights and Current. Do not exceed 5 sentences total. Use spacing and line breaks. No bullets or lists. Use HTML Format.',
      promptHighlights:
        'Write a "highlights" section for an Investor Update from the submitted content. Write one or two short introduction sentences summarizing the highlights. Use bullets to list out the key items below the introduction. Use HTML Format',
      promptCurrent:
        'Write a "To-Do" section for an Investor Update from the submitted content. Write one or two short introduction sentences summarizing the highlights. Use bullets to list out the key items below the introduction. Use HTML Format.',
      promptLowlights:
        'Write a "Lowlights" section for an Investor Update from the submitted content. Do not exceed two sentence per item you deem important. No bullets or lists. Use HTML Format.',
    });
  }, [businessId]);

  return (
    <div className="mt-3 h-full w-full">
      {displaySpinner()}
      <div className={loading ? 'blur' : ''}>
        <div className="mb-5 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-2">
          <div>
            <ExplainerText
              text="Let’s get started! By answering just a few questions, I can
                initiate the creation of your investor update."
            />
            <AssistantForm
              businessId={businessId}
              reportAssistant={reportAssistant}
              setReportAssistant={setReportAssistant}
              error={errorReportAssistant}
              setError={setErrorReportAssistant}
              submitHandle={submitHandle}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportAssistant;
