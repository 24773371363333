import Card from 'components/card';
import Spinner from 'components/spinner/Spinner';
import { BusinessReportItem } from 'interfaces/business/BusinessReport';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Preview from './components/Preview';
import { Link } from 'react-router-dom';
import {
  getBusinessReportSlug,
  loadBusinessReport,
} from 'services/business/BusinessReport';
import ModalShareLink from './components/ModalSharLink';
import ModalSendAsTest from './components/ModalSendAsTest';
import { ExplainerText } from 'components/explainerText/explainerText';

const ReportPreview = () => {
  const { businessId, reportId, assistant } = useParams();
  const [report, setReport] = useState<BusinessReportItem | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<string>(null);
  const [openModalShareLink, setOpenModalShareLink] = useState<boolean>(false);
  const [openModalSendAsTest, setOpenModalSendAsTest] =
    useState<boolean>(false);

  const loadReport = async () => {
    setLoading(true);
    loadBusinessReport(parseInt(reportId), (r) => {
      setReport(r.data);
      setLoading(false);
    });
  };

  const loadReportUrl = async () => {
    if (url === null) {
      getBusinessReportSlug(parseInt(reportId), (r) => {
        const url =
          window.location.origin +
          '/report/{slug}'.replace('{slug}', encodeURIComponent(r.data.slug));
        setUrl(url);
        setOpenModalShareLink(true);
      });
    } else {
      setOpenModalShareLink(true);
    }
  };

  const handleSendAsTest = () => {
    setOpenModalSendAsTest(true);
  };

  const displayEditing = () => {
    if (report?.approved !== true) {
      return (
        <div className="grid place-content-center items-center">
          <Link
            to={
              '/business/:businessId/report/edit/:reportId'
                .replace(':businessId', businessId)
                .replace(':reportId', reportId) +
              (assistant !== undefined ? '/assistant' : '')
            }
            className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Continue Editing
          </Link>
        </div>
      );
    } else {
      return (
        <div className="grid grid-cols-2 justify-items-center">
          <div>
            <button
              onClick={handleSendAsTest}
              className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Send as Email
            </button>
          </div>
          <div>
            <button
              onClick={loadReportUrl}
              className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Get Shareable Link
            </button>
          </div>
        </div>
      );
    }
  };

  const displayAsistant = () => {
    if (assistant !== undefined) {
      return (
        <ExplainerText text="Thank you for providing the background information! I've prepared an initial draft of your investor update, which you can find below. Please feel free to make any necessary edits. Additionally, you'll have the opportunity to customize various other elements of the investor update as you see fit." />
      );
    }
    return <></>;
  };

  useEffect(() => {
    loadReport();
  }, [reportId]);

  return (
    <div className="mt-3 h-full w-full">
      <div className="mb-5 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-2">
        <div className="h-full w-full rounded-xl">
          {displayAsistant()}
          <Card extra={'px-[20px] py-[20px] mb-5'}>
            {loading ? <Spinner /> : <Preview report={report} />}
          </Card>

          <Card extra={'px-[20px] py-[20px] mb-5'}>{displayEditing()}</Card>
        </div>
      </div>
      <ModalShareLink url={url} open={openModalShareLink} />
      <ModalSendAsTest
        report={report}
        open={openModalSendAsTest}
        setOpen={setOpenModalSendAsTest}
      />
    </div>
  );
};

export default ReportPreview;
