const getViolations = <T,>(e: any): T => {
    let errorFrom: T;

    if (e.response.status === 422) {
        for (const field of e.response.data.violations) {
            const key = field.propertyPath.split('[')[0] as keyof typeof errorFrom;
            errorFrom = {...errorFrom, [key]: field.message};
        }
        return errorFrom;
    }
}

export default getViolations;