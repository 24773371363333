import Table from "components/datatable/Table";
import { useTableContext } from "components/datatable/TableProvider";
import { useEffect } from "react";
import { TableColumn } from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";

interface Business {
    id: number;
    name: string;
    createdAt: string;
  }

const TableBusiness = () => {
    const table = useTableContext();

    const displayDate = (report: Business): string => {
        const date = new Date(report.createdAt);

        const formatter = new Intl.DateTimeFormat('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        const formattedDate = formatter.format(date);

        return formattedDate;
    }

    const columns: TableColumn<Business>[] = [
        // {
        //     name: 'Id',
        //     selector: (row) => row.id,
        // },
        {
            name: 'Name',
            cell: (row) => (
                <Link to={`/business/${row.id}/reports-dashboard/`}>{row.name}</Link>
            ),
        },
        {
            name: 'Created',
            selector: (row) => displayDate(row),
        },
        {
            name: '',
            cell: (row) => (
                <Link to={`/business/${row.id}/reports-dashboard/`} className="linear rounded-lg bg-blue-900 text-[13px] px-6 py-1 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">Create Update</Link>
            ),
            right: true
        }
    ];

    useEffect(()=> {
        table.setColumns(columns);
        table.load(1);
    }, []);


    return <Table />
}

export default TableBusiness;