import axios from "axios";
import useLocalStorage from "./useLocalStorage";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const signInUrl = process.env.REACT_APP_API_URL + '/login_check';

export interface UserData {
    username: string,
    password: string
};

export interface User {
    id: number,
    token: string,
    refresh_token: string,
    isFirstLogin: boolean
}

interface jwtToken {
    iat: number,
    exp: number;
    roles: string[],
    username: string
}

const useAuth = () => {
    const [user, setUser] = useLocalStorage({ key: "user", defaultValue: null });
    const navigate = useNavigate();

    const signIn = async (data: UserData) => {
        try {
            let authresult = await axios.post(signInUrl, data);
            const user: User = authresult.data;
            setUser(user);

            if (user.isFirstLogin) {
                return navigate('/welcome');
            }

            return navigate('/');
        } catch (err) {
            throw err;
        }
    };

    const signOut = () => {
        setUser(null);

        return navigate('/');
    };

    const decodeToken = (): jwtToken => {
        return jwt_decode(user.token);
    }

    const getEmail = (): string => {
        try {
            const object = decodeToken();

            return object.username;
        } catch (e) {
            return ''
        }
    }

    const isAuthorized = (): boolean => {
        return !!user;
    }

    const isFirstLogin = (): boolean => {
        return user?.isFirstLogin;
    }

    const updateFirstLogin = async () => {
        if (user.isFirstLogin) {
            const id = user.id;
            const url = process.env.REACT_APP_API_URL + "/users/" + id;
            await axios.put(url, { firstLogin: false });

            setUser({ ...user, isFirstLogin: false });
        }
    }

    return { user, signIn, signOut, getEmail, isAuthorized, isFirstLogin, updateFirstLogin };
}

export default useAuth;