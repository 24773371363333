import Switch from 'components/switch';
import { MediaObject } from 'interfaces/MediaObject';
import { BusinessReport } from 'interfaces/business/BusinessReport';
import { Dispatch, SetStateAction } from 'react';
import { MdFileUpload } from 'react-icons/md';
import DropZonefile2 from 'views/admin/main/ecommerce/settingsProduct/components/DropZonefile2';
import defaultLogo from 'assets/img/crm/defaultLogo.png';

interface ImageCardProps {
  name: string;
  field: string;
  description?: string;
  media: string | MediaObject;
  report: BusinessReport;
  setReport: Dispatch<SetStateAction<BusinessReport>>;
  logo?: File;
  setLogo: Dispatch<SetStateAction<File>>;
  handleRemove?: () => void;
  defaultContent?: boolean;
  addBottomLine?: boolean;
}
const ImageCard = ({
  name,
  field,
  description,
  media,
  report,
  logo,
  setReport,
  setLogo,
  handleRemove,
  defaultContent,
  addBottomLine,
}: ImageCardProps) => {
  const handleChange = () => {
    setReport({ ...report, [field]: !report[field as keyof typeof report] });
  };
  return (
    <div className="mt-5">
      <div className="flex items-center">
        <Switch
          color="blue"
          checked={report[field as keyof typeof report]}
          onChange={handleChange}
        />
        <p className="ml-2 font-bold text-blue-900 dark:text-white">{name}</p>
      </div>
      <div className="ml-2 mt-3">{description}</div>
      <div
        className={`mt-5 transition-all duration-200 ${
          report[field as keyof typeof report]
            ? 'opacity-100'
            : 'hidden opacity-0'
        }`}
      >
        <div className="flex">
          <DropZonefile2
            disabled={!!report[field as keyof typeof report]}
            setFile={setLogo}
            media={media}
            handleRemove={handleRemove}
            content={
              <div className=" border-sky-500 mr-2 rounded-2xl border-2 border-dotted p-3 px-6">
                <div className="box-border flex items-center justify-center rounded-xl py-2 dark:!border-navy-700 lg:pb-0">
                  <MdFileUpload className="text-[40px]  text-blue-900 dark:text-white" />
                </div>
                <h4 className="ml-2 text-sm font-bold text-blue-900 dark:text-white">
                  Upload Image
                </h4>
              </div>
            }
          />
          {defaultContent &&
          report.logoMedia === undefined &&
          logo === undefined ? (
            <div className="border-sky-500 ml-2 mr-4 box-content w-[101.5px] rounded-2xl border-2 border-dotted px-6 pb-[11px] pt-[8px]">
              <div className="mt-2 box-border flex items-center justify-center rounded-xl py-2 align-top dark:!border-navy-700 lg:pb-0">
                <img
                  src={defaultLogo}
                  style={{ width: 60 }}
                  alt="Default Logo"
                />
              </div>
              <div className="text-center">
                <button onClick={undefined}>[remove]</button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {addBottomLine ? (
        <hr className="mb-2 mr-2 mt-7 h-[2px] bg-blue-900" />
      ) : (
        ''
      )}
    </div>
  );
};

export default ImageCard;
