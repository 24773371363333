import { BusinessReportItem } from "interfaces/business/BusinessReport";

interface FooterPhoneAddressProps {
    report: BusinessReportItem
}

const FooterAddress = ({ report }: FooterPhoneAddressProps) => {
    if (report.footerBusinessAddress) {
        return <div className="mt-10 text-center">
            <p>{report.business.name}</p>
            <p>{report.business.streetAddress}</p>
            <p>{report.business.city}, {report.business.state} {report.business.zip}</p>
            <p>{report.business.country}</p>
        </div>
    }
}

export default FooterAddress;