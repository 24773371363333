import axios from "axios";
import { BusinessReport, BusinessReportErrorForm } from "interfaces/business/BusinessReport";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { loadBusinessReport } from "services/business/BusinessReport";
import BusinessReportForm from "../add/components/BusinessReportForm";
import Spinner from "components/spinner/Spinner";
import getViolations from "components/form/error/getViolations";

const ReportEdit = () => {
    const { reportId, businessId, assistant } = useParams();
    const [report, setReport] = useState<BusinessReport | null>(null);
    const [error, setError] = useState<BusinessReportErrorForm>({});
    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    const loadReport = async () => {
        loadBusinessReport(parseInt(reportId), r => {
            setReport(r.data);
            setLoading(false);
        });
    }

    const handleChange = (report: BusinessReport) => {
        const url = process.env.REACT_APP_API_URL + '/business_reports/{id}'.replace('{id}', reportId);

        axios.put(url, report).then(r => {
            navigateToPreview(r.data.id);
        }).catch(e => {
            const errorForm = getViolations<BusinessReportErrorForm>(e);
            setError(errorForm);
        });
    }

    const navigateToPreview = (reportId:string) => {
        return navigate('/business/:businessId/report/:reportId'.replace(':businessId', businessId).replace(':reportId', reportId))
    }
    useEffect(() => {
        loadReport();
    }, [reportId]);

    useEffect(()=> {
        if(report?.approved !== undefined && report.approved === true){
            navigateToPreview(report.id.toString());
        }
    }, [report])

    return <div className="mt-3 h-full w-full">
        <div className="mb-5 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-2">
            {loading ? <Spinner /> :
                <BusinessReportForm
                    businessId={parseInt(businessId)}
                    report={report}
                    setReport={setReport}
                    error={error}
                    handleChange={handleChange}
                    assistantAI={assistant !== undefined}
                />
            }
        </div>
    </div>
}

export default ReportEdit;