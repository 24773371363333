import { MediaObject } from "interfaces/MediaObject";
import defaultLogo from "assets/img/crm/defaultLogo.png";

interface ImageLineProps {
    flag: boolean,
    image: MediaObject
    defaultContent?: boolean
}

const ImageLine = ({ flag, image, defaultContent }: ImageLineProps) => {
    if (flag && image !== undefined) {
        return <div className="flex items-center justify-center">
            <img src={image.contentUrl} />
        </div>
    } else if (defaultContent !== undefined && defaultContent === true) {
        return <div className="flex items-center justify-center">
            <img src={defaultLogo} />
        </div>
    }
}

export default ImageLine;