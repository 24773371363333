import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { GoogleOAurhToken } from "interfaces/oauth/GoogleOAuthToken";
import { Dispatch, SetStateAction } from "react";

interface GoogleAuthProps {
    setToken: Dispatch<SetStateAction<GoogleOAurhToken>>;
    handleSuccess?: () => void;
}

const useGoogleAuth = ({ setToken, handleSuccess }: GoogleAuthProps) => {
    const createToken = async (code: string) => {
        const url = process.env.REACT_APP_API_URL + '/google_o_auth_tokens'
        axios.post(url, { code: code })
            .then(response => {
                setToken(response.data);
                handleSuccess();
            })
            .catch(e => console.log(e));
    }

    const googleLogin = useGoogleLogin({
        scope: "https://www.googleapis.com/auth/drive",
        onSuccess: async tokenResponse => {
            console.log(tokenResponse);
            createToken(tokenResponse.code)
        },
        flow: 'auth-code'
    });

    return { googleLogin };
}

export default useGoogleAuth;