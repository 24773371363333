const Input = (props: {
    id: string;
    extra?: string;
    placeholder?: string;
    state?: string;
    disabled?: boolean;
    type?: string;
    onChange?: any;
    value?: any
}) => {
    const {
        id,
        extra,
        type,
        placeholder,
        state,
        disabled,
        onChange,
        value,
    } = props;

    return (
        <div className={`${extra}`}>
            <input
                name={id}
                onChange={onChange}
                disabled={disabled}
                value={value}
                type={type}
                id={id}
                placeholder={placeholder}
                className={`flex h-10 w-full items-center text-blue-900 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${disabled === true
                    ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                    : state === "error"
                        ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                        : state === "success"
                            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                            : "border-gray-200 dark:!border-white/10 dark:text-white"
                    }`}
            />
        </div>)
}

export default Input;