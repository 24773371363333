import { Business, BusinessErrorForm } from "interfaces/business/Business";
import FormInputField from "./FormInputField";
import FormSelectField from "./FormSelectField";
import { Country, State } from 'country-state-city';

interface VentureFormProps {
    business: Business,
    setBusiness: React.Dispatch<React.SetStateAction<Business>>,
    error: BusinessErrorForm
}

const VentureForm = ({ business, setBusiness, error }: VentureFormProps) => {
    const renderSelectCountry = () => {
        return Country.getAllCountries().map(item => {
            return <option value={item.isoCode} key={item.isoCode}>{item.name}</option>;
        });
    }

    const renderSelectState = () => {
        return State.getStatesOfCountry(business.country).map(item => {
            return <option value={item.isoCode} key={item.isoCode}>{item.name}</option>
        });
    }

    const setCountry = (country: string) => {
        const defaultState = State.getStatesOfCountry(country)[0];
        setBusiness({ ...business, country: country, state: defaultState.isoCode });
    }

    return <>
        <FormInputField
            label="Business Name"
            id="name"
            value={business}
            setValue={setBusiness}
            errorMessage={error.name}
        />
        <FormInputField
            label="Street Addresse"
            id="streetAddress"
            value={business}
            setValue={setBusiness}
            errorMessage={error.streetAddress}
        />
        <FormInputField
            label="City"
            id="city"
            value={business}
            setValue={setBusiness}
            errorMessage={error.city}
        />
        <FormSelectField
            label="State"
            id="state"
            value={business.state || ''}
            onChange={e => setBusiness({ ...business, state: e.target.value })}
            render={renderSelectState}
        />
        <div className="grid grid-cols-2 gap-3">
            <div className="col-span-2 md:col-span-1">
                <FormInputField
                    label="Zip"
                    id="zip"
                    value={business}
                    setValue={setBusiness}
                    errorMessage={error.zip}
                />
            </div>
            <div className="col-span-2 md:col-span-1">
                <FormSelectField
                    label="Country"
                    id="country"
                    value={business.country || 'US'}
                    onChange={e => setCountry(e.target.value)}
                    render={renderSelectCountry}
                    className="col-span-2"
                />
            </div>
        </div>

        <FormInputField
            label="Business Phone"
            id="phone"
            value={business}
            setValue={setBusiness}
            errorMessage={error.phone}
        />

        <FormInputField
            label="Website"
            id="website"
            value={business}
            setValue={setBusiness}
            isOptional={true}
            errorMessage={error.website}
        />

        <FormInputField
            label="Business Email Address"
            id="email"
            value={business}
            setValue={setBusiness}
            errorMessage={error.email}
        />

        <FormInputField
            label="Business Type"
            id="category"
            value={business}
            setValue={setBusiness}
            errorMessage={error.category}
        />

        <FormInputField
            label="Business Linkedin Profile URL"
            id="linkedinUrl"
            value={business}
            setValue={setBusiness}
            isOptional={true}
        />

        <FormInputField
            label="Business Twitter Profile URL"
            id="twitterUrl"
            value={business}
            setValue={setBusiness}
            isOptional={true}
        />
    </>
}

export default VentureForm;