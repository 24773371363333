import InputField from "components/fields/InputField";
import { ChangeEvent, useEffect, useState } from "react";

interface FormInputFieldProps<T> {
    label: string,
    id: string,
    value: T,
    setValue: React.Dispatch<React.SetStateAction<T>>
    errorMessage?: string,
    isOptional?:boolean
}

const FormInputField = <T,> ({ id, label, value, setValue, errorMessage, isOptional }: FormInputFieldProps<T>) => {
    const [error, setError] = useState<string>('');
    
    const handleChangeInut = (event: ChangeEvent<HTMLInputElement>) => {
        setValue({...value, [id]: event.target.value}) 
    };
    
    useEffect(()=> setError(errorMessage), [errorMessage]);

    useEffect(()=> setError(''), [value[id as keyof typeof value]]);

    return <>
        <InputField
            label={label + (isOptional ? ' [optional]': '')}
            placeholder={label}
            id={id}
            value={value[id as keyof typeof value] || ''}
            type="text"
            onChange={handleChangeInut}
            state={error === '' || error === undefined ? '' : 'error'}
        />
        <small className="text-red-500">{error}</small>
    </>
}

export default FormInputField;