interface SpinnerProps {
    className?: string
  }

const Spinner = ({ className }: SpinnerProps) => {
    return <div className="mt-10"><div className={`grid place-items-center  ${className ? className : ''}`} >
        <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600" />
        <p>Loading...</p>
    </div></div>
}


export default Spinner;