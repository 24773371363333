import Card from 'components/card';
import ChartCard from './components/ChartCard';
import { useEffect, useMemo, useState } from 'react';
import { ChartData } from 'interfaces/business/ChartData';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BusinessProjection } from 'interfaces/business/BusinessProjection';
import useAutoSave from 'components/autosave/useAutoSave';
import { ExplainerText } from '../../../components/explainerText/explainerText';

const Projection = () => {
  const { businessId } = useParams();
  const [projection, setProjection] = useState<BusinessProjection>();

  const userHeader = (sum: string) => {
    return (
      <>
        <p>
          <span className="text-[0.875rem]">{sum}</span> In one year
        </p>
        <p className="mt-10 text-[0.875rem]">
          In a year with linear growth of{' '}
          <span className="text-blue-600">
            {userData?.growth !== undefined ? userData.growth : 'XX'}
          </span>{' '}
          users per month and{' '}
          <span className="text-red-600">
            {userData?.churn !== undefined ? userData.churn : 'XX'}%
          </span>{' '}
          churn you’ll have <span className="text-green-600">{sum}</span> users.
        </p>
      </>
    );
  };

  const generateUserData = () => {
    if (
      userData !== undefined &&
      userData.start !== null &&
      userData.growth !== null &&
      userData.churn !== null
    ) {
      let sum: number[] = [];

      const churnUser = 1 - userData.churn / 100;

      for (let i = 0; i < 12; i++) {
        let last = i > 0 ? sum[i - 1] : userData.start;

        sum[i] = last * churnUser + userData.growth;
      }

      return sum;
    }

    return [];
  };

  const mmrHeader = (sum: string) => {
    return (
      <>
        <p>
          <span className="text-2xl">${sum}</span> In one year
        </p>
        <p className="mt-10">
          In a year with linear user growth, an avg revenue of{' '}
          <span className="text-blue-600">
            {mmrData?.growth !== undefined ? mmrData.growth : 'XX'}
          </span>{' '}
          per customer{' '}
          <span className="text-red-600">
            {mmrData?.churn !== undefined ? mmrData.churn : 'XX'}%
          </span>{' '}
          churn hour MMR will be <span className="text-green-600">${sum}</span>.
        </p>
      </>
    );
  };

  const generateMrrData = () => {
    if (
      userData !== undefined &&
      userData.start !== null &&
      userData.growth !== null &&
      userData.churn !== null &&
      mmrData.start !== null &&
      mmrData.growth !== null &&
      mmrData.churn !== null
    ) {
      let sum: number[] = [];
      let user: number[] = [];

      const churnUser = 1 - userData.churn / 100;
      const churnMMr = mmrData.churn / 100;

      for (let i = 0; i < 12; i++) {
        let lastUser = i > 0 ? user[i - 1] : userData.start;
        user[i] = lastUser * churnUser + userData.growth;

        let first = mmrData.start;
        let revenueUser = first + user[i] * mmrData.growth;
        let revenueChrun = churnMMr * revenueUser;
        sum[i] = revenueUser - revenueChrun;
      }

      return sum;
    }

    return [];
  };

  const revenueHeader = (sum: string) => {
    return (
      <>
        <p>
          <span className="text-2xl">${sum}</span> Next 12 months
        </p>
        <p className="mt-10">
          Over the next 12 months you’ll bill about{' '}
          <span className="text-green-600">${sum}</span>
        </p>
      </>
    );
  };

  const loadProjection = async () => {
    const url =
      process.env.REACT_APP_API_URL +
      '/businesses/{id}/projection'.replace('{id}', businessId);

    axios
      .get(url)
      .then((r) => {
        setProjection(r.data);
      })
      .catch((e) =>
        setProjection({
          ...projection,
          business: 'businesses/' + businessId,
          userStart: 0,
          userGrowth: 4,
          userChurn: 3.6,
          mmrStart: 0,
          mmrGrowth: 49,
          mmrChurn: 3.3,
        })
      );
  };

  const saveProjection = async (data: BusinessProjection) => {
    if (data?.id) {
      const url =
        process.env.REACT_APP_API_URL +
        '/business_projections/{id}'.replace('{id}', data.id.toString());

      axios.put(url, data);
    } else if (
      data !== undefined &&
      (data.userStart !== undefined ||
        data.userGrowth !== undefined ||
        data.userChurn !== undefined ||
        data.mmrStart !== undefined ||
        data.mmrGrowth !== undefined ||
        data.mmrChurn !== undefined)
    ) {
      const url = process.env.REACT_APP_API_URL + '/business_projections';

      axios.post(url, data).then((r) => setProjection(r.data));
    }
  };

  const setUserData = (value: ChartData) => {
    const newProjection = {
      ...projection,
      userStart: value.start,
      userGrowth: value.growth,
      userChurn: value.churn,
    };

    dispatchAutoSave(newProjection);

    setProjection(newProjection);
  };

  const getUser = (): ChartData => {
    const data: ChartData = {
      start: projection?.userStart,
      growth: projection?.userGrowth,
      churn: projection?.userChurn,
    };

    return data;
  };

  const userData = useMemo(() => getUser(), [projection]);

  const setMmrData = (value: ChartData) => {
    const newProjection = {
      ...projection,
      mmrStart: value.start,
      mmrGrowth: value.growth,
      mmrChurn: value.churn,
    };

    dispatchAutoSave(newProjection);

    setProjection(newProjection);
  };

  const getMmr = (): ChartData => {
    const data: ChartData = {
      start: projection?.mmrStart,
      growth: projection?.mmrGrowth,
      churn: projection?.mmrChurn,
    };

    return data;
  };

  const mmrData = useMemo(() => getMmr(), [projection]);

  const { dispatchAutoSave } = useAutoSave<BusinessProjection>({
    onSave: saveProjection,
  });

  useEffect(() => {
    loadProjection();
  }, [businessId]);

  return (
    <div className="mt-3 w-full">
      <div>
        <ExplainerText 
        extra='mt-6'
        text="A lightweight revenue projections tool enabling you to simulate customer & revenue growth." 
        text2='Generate projections for Customers, Monthly Recurring Revenue (MRR) and Overall Revenue, using average revenue per customers as a key factor in the modeling process'/>

        <ChartCard
          title="Customers"
          textHeader={userHeader}
          startLabel="Starting Users"
          growthType="Growth Type"
          growthLabel="User Growth (month)"
          churnLabel="User Churn (month) %"
          data={userData}
          setData={setUserData}
          generateData={generateUserData}
          sumType="last"
        />

        <ChartCard
          title="Monthly Recurring Revenue"
          textHeader={mmrHeader}
          startLabel="Starting MMR"
          growthType="Growth Type"
          growthLabel="Avg Revenue per Customer (month)"
          churnLabel="Revenue Churn (month) %"
          data={mmrData}
          setData={setMmrData}
          generateData={generateMrrData}
          sumType="last"
        />

        <ChartCard
          title="Revenue"
          textHeader={revenueHeader}
          data={mmrData}
          generateData={generateMrrData}
          sumType="sum"
        />
      </div>
    </div>
  );
};

export default Projection;
