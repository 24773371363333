import { useContext, useState } from 'react';
import { useAuthContext } from 'components/auth/AuthProvider';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import { WizardContext } from 'components/wizard/WizardProvider';

const Wizard = () => {
  const [step, setStep] = useState<number>(0);
  const auth = useAuthContext();
  const {
    wizardBusinessName,
    setWizardBusinessName,
    wizardBusinessWebsite,
    setWizardBusinessWebsite,
  } = useContext(WizardContext);

  const renderStep = () => {
    switch (step) {
      case 1: {
          return (
              <Step1
              name={wizardBusinessName}
              setName={setWizardBusinessName}
              website={wizardBusinessWebsite}
              setWebsite={setWizardBusinessWebsite}
              setStep={setStep}
              />
              );
            }
            case 2: {
                return <Step3 setStep={setStep} />;
            }
            case 3: {
                return <Step4 setStep={setStep} />;
            }
            default: {
          auth.updateFirstLogin();
        return <Step2 setStep={setStep} />;
      }
    }
  };

  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px]">
      {renderStep()}
    </div>
  );
};

export default Wizard;
