import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import { Editor } from '@tinymce/tinymce-react';
import Card from 'components/card';
import Button from '../fields/Button';

interface ModalBoxProps {
  body: ReactNode;
  header: ReactNode;
  action: ReactNode;
  modalVisible: boolean;

  typeCloseButton?: 'brand' | 'gray' | 'navy' | 'green' | 'red' | 'blue';
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  onSave?: (newContent: string) => void;
  initialFocusRef?: React.RefObject<any>;
  finalFocusRef?: React.RefObject<any>;
  modalWidth?: string;
}

const ModalBox: React.FC<ModalBoxProps> = ({
  body,
  header,
  action,
  modalVisible,
  typeCloseButton,
  setModalVisible,
  onSave,
  initialFocusRef,
  finalFocusRef,
  modalWidth,
}: ModalBoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);

  const saveChanges = () => {
    const cleanedContent = content.replace(/<p>/g, '').replace(/<\/p>/g, '');

    onSave && onSave(cleanedContent);
    onClose();
    closeEditor();
  };

  useEffect(() => {
    const plainTextContent = React.isValidElement(body)
      ? ReactDOMServer.renderToStaticMarkup(body)
      : String(body);

    // Convert HTML entities to characters
    const decodedContent =
      new DOMParser().parseFromString(plainTextContent, 'text/html').body
        .textContent || '';

    // Remove unwanted HTML tags
    const cleanedContent = decodedContent.replace(/<\/?[^>]+(>|$)/g, '');

    // Exclude double quotes at the start and end of the tweet
    const trimmedContent = cleanedContent.trim();
    const isWrappedInQuotes =
      trimmedContent.startsWith('"') && trimmedContent.endsWith('"');
    const finalContent = isWrappedInQuotes
      ? trimmedContent.slice(1, -1)
      : trimmedContent;

    setContent(finalContent);
  }, [body]);

  const handleEditorChange = (newContent: string) => {
    setContent(newContent);
  };

  const openEditor = () => {
    setEditing(true);
    onOpen();
  };

  const closeEditor = () => {
    setEditing(false);
    onClose();
  };

  const shouldHighlightRed = (header: React.ReactNode): boolean => {
    const extractText = (node: React.ReactNode): string => {
      if (React.isValidElement(node)) {
        return React.Children.toArray(node.props.children)
          .map((child) => extractText(child))
          .join('');
      } else if (typeof node === 'string') {
        return node;
      } else {
        return '';
      }
    };

    const headerText = extractText(header);

    if (headerText === undefined) {
      console.warn('Header content is undefined!');
      return false;
    }

    // console.log('Header content:', headerText);

    return /delete|restore/i.test(headerText);
  };

  return (
    <>
      <Modal
        initialFocusRef={initialFocusRef}
        finalFocusRef={finalFocusRef}
        blockScrollOnMount={false}
        isOpen={editing}
        onClose={closeEditor}
      >
        <ModalOverlay />
        <ModalContent
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          h="100vh"
        >
          <Card
            extra={`mr-8 ml-8 h-auto px-4  sm:overflow-x-auto `}
            style={{ width: modalWidth }}
          >
            <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
              <ModalBody
                className="py-1 pt-4 focus:shadow-md"
                placeholder="Editor"
              >
                <Editor
                  value={content}
                  onEditorChange={handleEditorChange}
                  apiKey="yy7umtz7y7pt065d0sashe7rjha7b0ckbclvj3ftw1wqg8cx"
                  init={{
                    branding: false,
                    menubar: false,
                    height: 'auto',
                    width: '600px',
                    autoresize_bottom_margin: 0,
                    plugins: ['autoresize'],
                    toolbar: 'undo redo',
                    content_style: 'body { font-size: 14px }',
                    skin: 'oxide',
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <div className="mx-auto pb-4">
                  <Button
                    type="blue"
                    label="Save"
                    className="mt-2"
                    onClick={saveChanges}
                  />
                  <Button
                    type="red"
                    label="Cancel"
                    className="ml-4 mt-2"
                    onClick={closeEditor}
                  />
                </div>
              </ModalFooter>
            </div>
          </Card>
        </ModalContent>
      </Modal>

      <Modal
        initialFocusRef={initialFocusRef}
        finalFocusRef={finalFocusRef}
        isOpen={modalVisible}
        blockScrollOnMount={false}
        onClose={() => setModalVisible(false)}
      >
        <ModalOverlay bg="rgba(0,0,0,0.7)" />
        <ModalContent
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          h="100vh"
          minWidth={modalWidth}
        >
          <Card
            extra={`mr-8 ml-8 h-auto px-4  sm:overflow-x-auto ${
              editing ? 'hidden' : ''
            }`}
          >
            <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
              <ModalHeader
                className={`rounded-xl  bg-gray-200 py-3 pl-5 text-base font-bold text-blue-900`}
              >
                {header}
              </ModalHeader>
              <ModalBody className={'mx-auto max-w-screen-sm py-3'}>
                {body}
              </ModalBody>
              <ModalFooter>
                <div className="mx-auto pb-4">
                  {editing ? null : (
                    <>
                      <div className="inline-block">{action}</div>
                      {onSave && (
                        <Button
                          type="blue"
                          label="Edit"
                          className="ml-6  text-center"
                          onClick={openEditor}
                        />
                      )}
                    </>
                  )}
                  {editing ? null : (
                    <Button
                      type={typeCloseButton}
                      label="Cancel"
                      className="ml-6 mt-4 text-center"
                      onClick={() => setModalVisible(false)}
                    />
                  )}
                </div>
              </ModalFooter>
            </div>
          </Card>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalBox;
