import { GoogleOAuthProvider } from '@react-oauth/google';
import Card from 'components/card';
import TextAreaCard from './TextAreaCard';
import {
  BusinessReport,
  BusinessReportErrorForm,
} from 'interfaces/business/BusinessReport';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GoogleOAurhToken } from 'interfaces/oauth/GoogleOAuthToken';
import axios from 'axios';
import { BusinessReportGoogleSheets } from 'interfaces/business/BusinessReportGoogleSheets';
import { UploadFile } from 'services/UploadFile';
import CheckboxCard from '../CheckboxCard';
import ImageCard from './ImageCard';
import { BusinessReportIntegration } from 'interfaces/business/BusinessReportIntegration';
import IntegrationCard from './IntegrationCard';
import AlertGoogleOAuth from './AlertGoogleOAuth';
import { Link } from 'react-router-dom';
import ModalBox from '../../../../../components/base/ModalBox';
import Button from '../../../../../components/fields/Button';

interface BusinessReportFormProps {
  businessId: number;
  report: BusinessReport;
  setReport: Dispatch<SetStateAction<BusinessReport>>;
  error: BusinessReportErrorForm;
  handleChange: (report: BusinessReport) => void;
  assistantAI?: boolean;
}

const BusinessReportForm = ({
  businessId,
  report,
  setReport,
  error,
  handleChange,
  assistantAI,
}: BusinessReportFormProps) => {
  const tagLineRef = useRef(null);
  const commentaryRef = useRef(null);
  const highlightsRef = useRef(null);
  const lowlightsRef = useRef(null);
  const beginningPeriodCashRef = useRef(null);
  const endPeriodCashRef = useRef(null);
  const productUpdatesRef = useRef(null);
  const whereNeedHelpRef = useRef(null);
  const thankYouRef = useRef(null);
  const newCustomerGoogleSheetRef = useRef(null);
  const revenuePlanGoogleSheetRef = useRef(null);
  const monthlyChurnGoogleSheetRef = useRef(null);
  const kpiGoogleSheetRef = useRef(null);
  const [googleOAuthToken, setGoogleOAuthToken] =
    useState<GoogleOAurhToken | null>(null);
  const [googleOAuthTokenStatus, setOAuthTokenStatus] = useState<
    boolean | null
  >(null);
  const [newCustomerGoogleSheet, setNewCustomerGoogleSheet] =
    useState<BusinessReportGoogleSheets | null>(null);
  const [revenuePlanGoogleSheet, setRevenuePlanGoogleSheet] =
    useState<BusinessReportGoogleSheets | null>(null);
  const [monthlyChurnGoogleSheet, setMonthlyChurnGoogleSheet] =
    useState<BusinessReportGoogleSheets | null>(null);
  const [kpiGoogleSheet, setKpiGoogleSheet] =
    useState<BusinessReportGoogleSheets | null>(null);
  const [logo, setLogo] = useState<File>();
  const [mediaProduct, setMediaProduct] = useState<File>();
  const [newCustomerMedia, setNewCustomerMedia] = useState<File>();
  const [revenuePlanMedia, setRevenuePlanMedia] = useState<File>();
  const [monthlyChurnMedia, setMonthlyChurnMedia] = useState<File>();
  const [kpiMedia, setKpiMedia] = useState<File>();
  const [approved, setApproved] = useState<boolean>(false);
  const [approvedError, setApprovedError] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);

  const loadGoogleSheetsAuth = async () => {
    const url = process.env.REACT_APP_API_URL + '/google_o_auth_tokens';

    await axios
      .get(url)
      .then((response) =>
        setGoogleOAuthToken(response.data['hydra:member'][0])
      );
  };

  const checkGoogleSheetsStatus = async () => {
    if (googleOAuthToken) {
      const url =
        process.env.REACT_APP_API_URL +
        '/google_o_auth_tokens/{id}/status'.replace(
          '{id}',
          googleOAuthToken.id.toString()
        );

      await axios
        .get(url)
        .then((response) => setOAuthTokenStatus(response.data['active']));
    }
  };

  const createBusinessReportGoogleSheet = async (
    googleSheet: BusinessReportGoogleSheets
  ) => {
    const url =
      process.env.REACT_APP_API_URL + '/business_report_google_sheets';

    return await axios.post(url, googleSheet).then((r) => r.data);
  };

  const updateBusinessReportGoogleSheet = async (
    googleSheet: BusinessReportGoogleSheets
  ) => {
    const url =
      process.env.REACT_APP_API_URL +
      '/business_report_google_sheets/{id}'.replace(
        '{id}',
        googleSheet.id.toString()
      );

    return await axios.put(url, googleSheet).then((r) => r.data);
  };

  const handleIntegration = async (
    integration: BusinessReportIntegration,
    googleSheet: BusinessReportGoogleSheets,
    media: File
  ) => {
    let businessReportGoogleSheets: string = null;
    let businessMedia: string = null;

    if (googleSheet) {
      let reportGoogleSheets = null;
      if (googleSheet.id !== undefined) {
        reportGoogleSheets = await updateBusinessReportGoogleSheet(googleSheet);
      } else {
        reportGoogleSheets = await createBusinessReportGoogleSheet(googleSheet);
      }
      businessReportGoogleSheets = reportGoogleSheets['@id'];
    }

    if (media !== undefined && media !== null && media !== undefined) {
      const upload = await UploadFile(media);
      businessMedia = upload['@id'];
    } else if (
      integration !== undefined &&
      integration.mediaObject !== undefined &&
      integration.mediaObject !== null &&
      typeof integration.mediaObject !== 'string'
    ) {
      businessMedia = '/media_objects/' + integration.mediaObject.id;
    }

    if (businessReportGoogleSheets) {
      if (integration === undefined) {
        integration = {};
      }
      integration.googleSheets = businessReportGoogleSheets;
    }
    if (businessMedia) {
      if (integration === undefined) {
        integration = {};
      }
      integration.mediaObject = businessMedia;
    }

    return integration;
  };

  const prepareReport = async (): Promise<BusinessReport> => {
    report.tagLineText = tagLineRef.current.getContent();
    report.commentaryText = commentaryRef.current.getContent();
    report.highlightsText = highlightsRef.current.getContent();
    report.lowlightsText = lowlightsRef.current.getContent();
    report.beginningPeriodCashText =
      beginningPeriodCashRef.current.getContent();
    report.endPeriodCashText = endPeriodCashRef.current.getContent();
    report.productUpdatesText = productUpdatesRef.current.getContent();
    report.whereNeedHelpText = whereNeedHelpRef.current.getContent();
    report.thankYouText = thankYouRef.current.getContent();
    report.newCustomersText = newCustomerGoogleSheetRef.current.getContent();
    report.revenueText = revenuePlanGoogleSheetRef.current.getContent();
    report.monthlyChurnText = monthlyChurnGoogleSheetRef.current.getContent();
    report.kpiText = kpiGoogleSheetRef.current.getContent();
    report.business = '/businesses/' + businessId;

    if (
      logo === undefined &&
      typeof report.logoMedia !== 'string' &&
      report.logoMedia !== undefined
    ) {
      report.logoMedia = '/media_objects/' + report.logoMedia.id;
    } else if (logo === null) {
      report.logoMedia = null;
    } else if (logo !== undefined) {
      const upload = await UploadFile(logo);
      report.logoMedia = upload['@id'];
    }

    if (
      mediaProduct === undefined &&
      typeof report.pictureNewProductMedia !== 'string' &&
      report.pictureNewProductMedia !== undefined
    ) {
      report.pictureNewProductMedia =
        '/media_objects/' + report.pictureNewProductMedia.id;
    } else if (mediaProduct !== undefined) {
      const upload = await UploadFile(mediaProduct);
      report.pictureNewProductMedia = upload['@id'];
    }

    report.newCustomersIntegration = await handleIntegration(
      typeof report.newCustomersIntegration !== 'string' &&
        report.newCustomersIntegration !== undefined
        ? report.newCustomersIntegration
        : undefined,
      newCustomerGoogleSheet,
      newCustomerMedia
    );
    report.revenueIntegration = await handleIntegration(
      report.revenueIntegration,
      revenuePlanGoogleSheet,
      revenuePlanMedia
    );

    report.monthlyChurnIntegration = await handleIntegration(
      report.monthlyChurnIntegration,
      monthlyChurnGoogleSheet,
      monthlyChurnMedia
    );

    report.kpiIntegration = await handleIntegration(
      report.kpiIntegration,
      kpiGoogleSheet,
      kpiMedia
    );

    return report;
  };

  const handleButton = async () => {
    const report = await prepareReport();

    handleChange(report);
  };

  const removeLogoHandle = () => {
    setLogo(null);
  };
  const approvedChange = () => {
    setApproved(!approved);
  };

  const handleApprove = async () => {
    const report = await prepareReport();

    if (approved) {
      report.approved = true;
      handleChange(report);
    } else {
      setApprovedError(true);
    }
  };
  const [trimmedInputValue, setTrimmedInputValue] = useState('');

  const handleApproval = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setReport({ ...report, name: inputValue });

    setTrimmedInputValue(inputValue.trim());
  };

  useEffect(() => {
    if (report.name !== undefined) {
      setTrimmedInputValue(report.name);
    }
  }, []);

  useEffect(() => {
    if (trimmedInputValue !== '' && report.name !== undefined) {
      setApproved(true);
    } else {
      setApproved(false);
    }
  }, [trimmedInputValue, report.name]);

  useEffect(() => {
    loadGoogleSheetsAuth();
  }, [businessId]);

  useEffect(() => {
    if (
      report.newCustomersIntegration !== undefined &&
      typeof report.newCustomersIntegration !== 'string' &&
      report.newCustomersIntegration.googleSheets &&
      typeof report.newCustomersIntegration.googleSheets !== 'string'
    ) {
      setNewCustomerGoogleSheet(report.newCustomersIntegration.googleSheets);
    }
  }, [report.newCustomersIntegration]);

  useEffect(() => {
    if (
      report.revenueIntegration !== undefined &&
      typeof report.revenueIntegration !== 'string' &&
      report.revenueIntegration.googleSheets &&
      typeof report.revenueIntegration.googleSheets !== 'string'
    ) {
      setRevenuePlanGoogleSheet(report.revenueIntegration.googleSheets);
    }
  }, [report.revenueIntegration]);

  useEffect(() => {
    if (
      report.monthlyChurnIntegration !== undefined &&
      typeof report.monthlyChurnIntegration !== 'string' &&
      report.monthlyChurnIntegration.googleSheets &&
      typeof report.monthlyChurnIntegration.googleSheets !== 'string'
    ) {
      setMonthlyChurnGoogleSheet(report.monthlyChurnIntegration.googleSheets);
    }
  }, [report.monthlyChurnIntegration]);

  useEffect(() => {
    if (
      report.kpiIntegration !== undefined &&
      typeof report.kpiIntegration !== 'string' &&
      report.kpiIntegration.googleSheets &&
      typeof report.kpiIntegration.googleSheets !== 'string'
    ) {
      setKpiGoogleSheet(report.kpiIntegration.googleSheets);
    }
  }, [report.kpiIntegration]);

  useEffect(() => {
    checkGoogleSheetsStatus();
  }, [googleOAuthToken]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
      <div className="h-full w-full rounded-xl">
        {report.id === undefined && (
          <div className="mb-2 flex flex-col items-center gap-4 md:flex-row">
            <Link
              to={'/business/:businessId/assistant-report/add'.replace(
                ':businessId',
                businessId.toString()
              )}
              style={{ marginLeft: 'auto' }}
              className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              <button>Back to AI Writer Assistant</button>
            </Link>
          </div>
        )}

        <Card extra={'px-[20px] py-[20px] mb-5 text-blue-900'}>
          <p className="text-lg font-bold text-blue-900 dark:text-white">
            Select and edit a section to include in your Update
          </p>
          <ImageCard
            name="Company Logo | Image Upload"
            field="logo"
            description="Incorporating a company logo enhances professionalism, fostering
                              brand identity and instilling a visually cohesive and reputable
                              impression."
            media={report.logoMedia}
            report={report}
            setReport={setReport}
            setLogo={setLogo}
            logo={logo}
            handleRemove={removeLogoHandle}
            defaultContent={true}
            addBottomLine={true}
          />

          <TextAreaCard
            name="Tag Line | One Sentence Company Description"
            field="tagLine"
            description="A concise and memorable phrase encapsulating the essence or key
            message of a brand, product, or campaign."
            refer={tagLineRef}
            report={report}
            setValue={setReport}
            init={report.tagLineText}
            addBottomLine={true}
          />

          <TextAreaCard
            name="Opening Commentary"
            field="commentary"
            description="Succinctly highlight key achievements, address significant
            developments, and set the tone for the overall communication."
            refer={commentaryRef}
            report={report}
            setValue={setReport}
            init={report.commentaryText}
            assistantAI={assistantAI}
            addBottomLine={true}
          />

          <TextAreaCard
            name="What went well (Highlights)"
            field="highlights"
            description="Convey key achievements, milestones, and financial performance in
            a concise and impactful manner, providing investors with a quick
            overview of the company's progress."
            refer={highlightsRef}
            report={report}
            setValue={setReport}
            init={report.highlightsText}
            assistantAI={assistantAI}
            addBottomLine={true}
          />

          <TextAreaCard
            name="What went wrong (Lowlights)"
            field="lowlights"
            description="Essential for transparency and risk mitigation, providing insights
            into challenges faced, lessons learned, and the proactive
            strategies being implemented to address setbacks and enhance
            long-term resilience."
            refer={lowlightsRef}
            report={report}
            setValue={setReport}
            init={report.lowlightsText}
            assistantAI={assistantAI}
            addBottomLine={true}
          />

          <IntegrationCard
            label="Revenue plan vs actual | Graphic"
            field="revenuePlan"
            descriptionText="Transparently show the company's financial performance, enabling
            investors to assess the alignment of projected revenues with
            actual results, and understanding the factors influencing any
            variations."
            integrationField="revenueIntegration"
            report={report}
            setReport={setReport}
            googleOAuthToken={googleOAuthToken}
            setGoogleOAuthToken={setGoogleOAuthToken}
            googleSheet={revenuePlanGoogleSheet}
            setGoogleSheet={setRevenuePlanGoogleSheet}
            setMedia={setRevenuePlanMedia}
            refer={revenuePlanGoogleSheetRef}
            init={report.revenueText}
            addBottomLine={true}
          />

          <IntegrationCard
            label="New Customers | Graphic"
            field="newCustomers"
            descriptionText="Provide insights into customer acquisition and retention efforts,
            showcasing growth strategies, and addressing any challenges or
            successes related to customer dynamics."
            integrationField="newCustomersIntegration"
            report={report}
            setReport={setReport}
            googleOAuthToken={googleOAuthToken}
            setGoogleOAuthToken={setGoogleOAuthToken}
            googleSheet={newCustomerGoogleSheet}
            setGoogleSheet={setNewCustomerGoogleSheet}
            setMedia={setNewCustomerMedia}
            refer={newCustomerGoogleSheetRef}
            init={report.newCustomersText}
            defaultContent={true}
            chart={newCustomerMedia}
            addBottomLine={true}
          />

          <IntegrationCard
            label="Monthly Churn | Graphic"
            field="monthlyChurn"
            descriptionText="Quantify the rate at which customers discontinue services or products, providing insight into business sustainability and customer retention efforts."
            integrationField="monthlyChurnIntegration"
            report={report}
            setReport={setReport}
            googleOAuthToken={googleOAuthToken}
            setGoogleOAuthToken={setGoogleOAuthToken}
            googleSheet={monthlyChurnGoogleSheet}
            setGoogleSheet={setMonthlyChurnGoogleSheet}
            setMedia={setMonthlyChurnMedia}
            refer={monthlyChurnGoogleSheetRef}
            init={report.monthlyChurnText}
            addBottomLine={true}
          />

          <TextAreaCard
            name="Beginning of period cash"
            field="beginningPeriodCash"
            description="Essential for transparency, offering a starting point for assessing financial performance, and providing context for the company's cash position at the beginning of the reporting period."
            refer={beginningPeriodCashRef}
            report={report}
            setValue={setReport}
            init={report.beginningPeriodCashText}
            addBottomLine={true}
          />

          <TextAreaCard
            name="End of period cash"
            field="endPeriodCash"
            description=" A snapshot of the company's closing cash position, offering
                    investors a clear understanding of the financial liquidity and
                    performance at the conclusion of the reporting period."
            refer={endPeriodCashRef}
            report={report}
            setValue={setReport}
            init={report.endPeriodCashText}
            addBottomLine={true}
          />

          <IntegrationCard
            label="Any KPI’s used to run the business"
            field="kpi"
            descriptionText="Highlighting key performance indicators crucial for business
            operations, providing investors with insights into the metrics
            that drive and measure the company's success."
            integrationField="kpiIntegration"
            report={report}
            setReport={setReport}
            googleOAuthToken={googleOAuthToken}
            setGoogleOAuthToken={setGoogleOAuthToken}
            googleSheet={kpiGoogleSheet}
            setGoogleSheet={setKpiGoogleSheet}
            setMedia={setKpiMedia}
            refer={kpiGoogleSheetRef}
            init={report.kpiText}
            addBottomLine={true}
          />

          <TextAreaCard
            name="Product Updates and To Do Items"
            field="productUpdates"
            description="Vital for conveying progress, upcoming initiatives, and addressing
                    any pending tasks, offering investors a comprehensive view of the
                    company's strategic focus and future plans."
            refer={productUpdatesRef}
            report={report}
            setValue={setReport}
            init={report.productUpdatesText}
            assistantAI={assistantAI}
            addBottomLine={true}
          />

          <ImageCard
            name="Picture of new product or customer"
            field="pictureNewProduct"
            description="Visually showcase tangible outcomes, reinforcing transparency, and
                    providing investors with a compelling snapshot of recent product
                    developments or new customer acquisitions."
            media={report.pictureNewProductMedia}
            report={report}
            setReport={setReport}
            setLogo={setMediaProduct}
            addBottomLine={true}
          />

          <TextAreaCard
            name="Where we need help"
            field="whereNeedHelp"
            description="Request investor engagement, as it outlines specific challenges or
            areas where external support or expertise can contribute to the
            company's growth and success."
            refer={whereNeedHelpRef}
            report={report}
            setValue={setReport}
            init={report.whereNeedHelpText}
            addBottomLine={true}
          />

          <TextAreaCard
            name="Commentary on the report & thank you"
            field="thankYou"
            description="Essential for summarizing key insights, expressing gratitude for
            investors' support, and leaving a positive and appreciative
            impression at the conclusion of the communication."
            refer={thankYouRef}
            report={report}
            setValue={setReport}
            init={report.thankYouText}
            addBottomLine={true}
          />

          <CheckboxCard
            name="Footer: Phone / Address"
            field="footerBusinessAddress"
            description="Providing a direct point of contact, enhancing communication accessibility. If selected InvestorPulse will automatically insert the phone and address entered in the Business Details section."
            report={report}
            setValue={setReport}
            addBottomLine={true}
          />

          <CheckboxCard
            name="Footer: Follow us on Social Media"
            field="footerBusinessSocialMedia"
            description="Enhances connectivity, allowing stakeholders to stay updated on real-time. If selected InvestorPulse will automatically insert and link to the social media profiles entered in the Business Details section."
            report={report}
            setValue={setReport}
            addBottomLine={false}
          />
        </Card>

        <Card extra={'px-[20px] py-[20px] mb-5 text-blue-900'}>
          <p className="text-lg font-bold dark:text-white">
            Name the Update (required)
          </p>
          <p className="text-gray-6000 mt-1 text-base">
            This name will only show within InvestorPulse Dashboards
          </p>
          <input
            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            defaultValue={report.name}
            onChange={handleApproval}
          />
          <small className="mt-2 text-red-500">{error.name}</small>
        </Card>

        <Card extra={'px-[20px] py-[20px] mb-5'}>
          <div className="grid place-content-center items-center">
            <button
              className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleButton}
            >
              Generate Live Preview
            </button>
          </div>
          <div className="mt-5 grid place-content-center items-center">
            <button
              className={`linear rounded-xl px-10 py-2 text-base font-medium text-white transition duration-200 ${
                approved
                  ? 'bg-blue-900 hover:bg-brand-600 active:bg-brand-800 dark:bg-brand-200 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-400'
                  : 'bg-gray-500 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200'
              }`}
              onClick={() => setModalVisible(true)}
              disabled={!approved} // Disable the button if not approved
            >
              Approve Report
            </button>
          </div>
          <div className="mt-2">
            <div
              className={`flex place-content-center items-center ${
                approvedError ? 'text-red-500' : ''
              }`}
            >
              <div
                className={`text-sm ${
                  approved ? 'text-blue-900 dark:text-white' : 'text-gray-600'
                }`}
              >
                <p className="ml-1">
                  When you are finished with your update, you must approve it
                  before distributing it.{' '}
                </p>
                <p>
                  Once it is approved, you cannot change it, however, you can
                  publish a new report.
                </p>
              </div>
            </div>
          </div>
        </Card>

        {modalVisible && (
          <ModalBox
            body={<></>}
            action={
              <>
                <Button
                  type="gray"
                  label="Approve Update"
                  onClick={() => {
                    handleApprove();
                    setModalVisible(false);
                  }}
                />
              </>
            }
            header="Confirmation"
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            typeCloseButton="red"
          />
        )}

        <AlertGoogleOAuth
          googleOAuthToken={googleOAuthToken}
          setGoogleOAuthToken={setGoogleOAuthToken}
          active={googleOAuthTokenStatus}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default BusinessReportForm;
