import Card from "components/card";
import { Dispatch, SetStateAction } from "react";

interface Step2Props {
    setStep: Dispatch<SetStateAction<number>>;
}

const Step2 = ({ setStep }: Step2Props) => {
    const handleInvestor = () => setStep(2);
    const handleStartup = () => setStep(3);

    return <Card extra={"w-50p px-[20px] py-[20px]"}>
        <div className="w-full px-[8px] text-center">
            <h4 className="text-xl font-bold text-blue-900 dark:text-white">
            Please tell us about yourself!
                        </h4>
            <p className="mt-1 text-base text-gray-600">
            Your selection does not change the user experience or product features. It simply helps us understand who uses InvestorPulse.
            </p>
        </div>
        <div className="mt-10 m-auto flex flex-col items-center gap-4 md:row">
            <button
                className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleInvestor}
            >
                I am Investor
            </button>

            <button
                className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleStartup}
            >
                I am a Business Owner
            </button>
        </div>
    </Card>
}

export default Step2;