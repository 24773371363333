import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";

interface WizardContextI {
    wizardBusinessName: string,
    setWizardBusinessName: Dispatch<SetStateAction<string>>
    wizardBusinessWebsite: string,
    setWizardBusinessWebsite: Dispatch<SetStateAction<string>>
}

interface WizardProviderProps {
    children: React.ReactNode
}

export const WizardContext = createContext<WizardContextI>({
    wizardBusinessName: '',
    setWizardBusinessName: () => { },
    wizardBusinessWebsite: '',
    setWizardBusinessWebsite: () => { }
});

const WizardProvider = ({ children }: WizardProviderProps) => {
    const [wizardBusinessName, setWizardBusinessName] = useState<string>('');
    const [wizardBusinessWebsite, setWizardBusinessWebsite] = useState<string>('');

    return <WizardContext.Provider value={{ wizardBusinessName, setWizardBusinessName, wizardBusinessWebsite, setWizardBusinessWebsite }}>
        {children}
    </WizardContext.Provider>
}

export default WizardProvider;