import { Dispatch, SetStateAction, createContext, useContext } from "react";
import useTableData from "./useTableData";
import { TableColumn } from "react-data-table-component";

interface TableProviderInterface<T> {
    data: T[] | null,
    setData: Dispatch<SetStateAction<T[]>>;
    columns: TableColumn<T>[];
    setColumns: Dispatch<SetStateAction<TableColumn<T>[]>>;
    totalItems: number;
    setTotalItems: Dispatch<SetStateAction<number>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    load: (page: number) => void;
}

const TableContext = createContext<TableProviderInterface<any>>({
    data: null,
    setData: () => { },
    columns: [],
    setColumns: () => { },
    totalItems: 0,
    setTotalItems: () => { },
    loading: false,
    setLoading: () => { },
    load: () => { }
});

interface TableProviderPropsInterface {
    children: React.ReactNode;
    dataHanlder?: (page: number) => Promise<any>;
}
const useTableContext = () => useContext(TableContext);

const TableProvider = ({ children, dataHanlder }: TableProviderPropsInterface) => {
    const tableData = useTableData({ dataHanlder: dataHanlder });

    return <TableContext.Provider value={tableData}>
        {children}
    </TableContext.Provider>
}

export { TableProvider, useTableContext }