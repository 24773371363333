import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import { Dispatch, SetStateAction, useEffect } from "react";

interface ModalSuccessSendTestProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
}
const ModalSuccessSendTest = ({ open, setOpen }: ModalSuccessSendTestProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (open) {
            onOpen();
        } else {
            onClose();
        }
    }, [open]);

    useEffect(() => {
        onClose();
    }, []);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" zIndex='1000' />
        <ModalContent
            containerProps={{ zIndex: '2000' }}
            className="!z-[1002] !m-auto !w-max min-w-[650px] !max-w-[85%] md:top-[12vh]"
        >
            <ModalBody>
                <Card extra="px-[30px] pt-[35px] pb-[40px] max-w flex flex-col !z-[1004]">
                    <h3 className="mb-4 text-4xl font-extrabold leading-none">Success!</h3>
                    <p>Your Email has been successfully sent.</p>
                    <div className="mt-5 flex justify-between">
                        <div>
                            <div className="flex gap-2">
                                <button
                                    onClick={onClose}
                                    className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Card>
            </ModalBody>
        </ModalContent>
    </Modal>
}

export default ModalSuccessSendTest;