import { Business, BusinessErrorForm } from "interfaces/business/Business"
import VentureForm from "../../add/components/VentureForm"
import axios from "axios";
import getViolations from "components/form/error/getViolations";

interface ViewVentureFormProps {
    business: Business,
    setBusiness: React.Dispatch<React.SetStateAction<Business>>,
    error: BusinessErrorForm,
    setError: React.Dispatch<React.SetStateAction<BusinessErrorForm>>,
}
const ViewVentureForm = ({ business, setBusiness, error, setError }: ViewVentureFormProps) => {

    const host = process.env.REACT_APP_API_URL;
    const handleSaveBusiness = async () => {
        const url = host + '/businesses/{id}'.replace('{id}', business.id.toString());

        axios.put(url, business).then(r => {
            setBusiness(r.data);
            setError({});
        }).catch(e => {
            const errorForm = getViolations<BusinessErrorForm>(e);
            setError(errorForm);
        });
    }

    return <>
        <div className="mt-7 grid grid-cols-1 gap-3">
            <VentureForm business={business} setBusiness={setBusiness} error={error} />

            <div className="mt-4 ml-auto flex flex-col items-center gap-4 md:flex-row">
                <button
                    className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={handleSaveBusiness}
                >
                    Save
                </button>
            </div>
        </div>
    </>
}

export default ViewVentureForm