import axios from "axios";

const UploadFile = async (file: File) => {
    const url = process.env.REACT_APP_API_URL + '/media_objects';
    
    var formData = new FormData();
    formData.append('file', file);

    return await axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(r => r.data);
}

export {UploadFile};