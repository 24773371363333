import axios from 'axios';
import { TableProvider } from 'components/datatable/TableProvider';
import { Link } from 'react-router-dom';
import TableBusiness from 'views/main/report/dashboard/components/TableBusiness';
import Card from '../../../../components/card';
import { ExplainerText } from '../../../../components/explainerText/explainerText';

const host = process.env.REACT_APP_API_URL;

const loadBusinesses = async (page: number) => {
  let url = host + '/businesses';

  if (page > 1) {
    url += '?page=' + page;
  }

  const response = await axios.get(url);

  return response.data;
};

const Dashboard = () => {
  return (
    <>
      <ExplainerText
        text={`The list below shows your businesses and ventures. Click on the
          “Create Update” button to create an update.`}
        extra="mt-6"
      />
      <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px]">
        <TableProvider dataHanlder={loadBusinesses}>
          <TableBusiness />
        </TableProvider>
      </div>
      <div className="my-4 flex flex-col items-center gap-4 md:flex-row">
        <Link
          to="/venture/add"
          style={{ marginLeft: 'auto' }}
          className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          <button>+ Add Venture / Business</button>
        </Link>
      </div>
    </>
  );
};

export default Dashboard;
