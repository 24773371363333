import axios from "axios";
import { BusinessFounder, BusinessFounderError } from "interfaces/business/BusinessFounder";
import { useEffect, useState } from "react";
import FormInputField from "../../add/components/FormInputField";
import getViolations from "components/form/error/getViolations";

interface BusinessFounderFormProps {
    businessId: number;
}

const BusinessFounderForm = ({ businessId }: BusinessFounderFormProps) => {
    const [businessFounder, setBusinessFounder] = useState<BusinessFounder>({});
    const [errorForm, setErrorForm] = useState<BusinessFounderError>({});

    const loadBusinessFounder = async () => {
        const url = process.env.REACT_APP_API_URL + '/businesses/{id}/founder'
            .replace('{id}', businessId.toString());

        axios.get(url)
            .then(e => setBusinessFounder(e.data))
            .catch(e => { setBusinessFounder({ ...businessFounder, business: 'businesses/' + businessId }) });
    }

    const handleSaveBusinessFounder = async () => {

        if (businessFounder?.id) {
            const url = process.env.REACT_APP_API_URL + '/business_founders/{id}'
                .replace('{id}', businessFounder.id.toString());

            axios.put(url, businessFounder)
                .then(r => setBusinessFounder(r.data))
                .catch(e => {
                    const error = getViolations<BusinessFounderError>(e);
                    setErrorForm(error);
                });;
        } else {
            const url = process.env.REACT_APP_API_URL + '/business_founders'
            axios.post(url, businessFounder)
                .then(r => setBusinessFounder(r.data))
                .catch(e => {
                    const error = getViolations<BusinessFounderError>(e);
                    setErrorForm(error);
                });
        }
    }
    useEffect(() => {
        loadBusinessFounder();
    }, [businessId]);

    return <div className="mt-7 grid grid-cols-1 gap-3">
        <FormInputField
            label="Founder First Name"
            id="firstName"
            value={businessFounder}
            setValue={setBusinessFounder}
            errorMessage={errorForm.firstName}
        />

        <FormInputField
            label="Founder Last Name"
            id="lastName"
            value={businessFounder}
            setValue={setBusinessFounder}
            errorMessage={errorForm.lastName}
        />

        <FormInputField
            label="Title/Position"
            id="position"
            value={businessFounder}
            setValue={setBusinessFounder}
            errorMessage={errorForm.position}
            isOptional={true}
        />

        <FormInputField
            label="Phone"
            id="phone"
            value={businessFounder}
            setValue={setBusinessFounder}
            errorMessage={errorForm.phone}
        />

        <FormInputField
            label="Email"
            id="email"
            value={businessFounder}
            setValue={setBusinessFounder}
            errorMessage={errorForm.email}
        />

        <FormInputField
            label="Founder Linkedin Profile"
            id="linkedin"
            value={businessFounder}
            setValue={setBusinessFounder}
            errorMessage={errorForm.linkedin}
            isOptional={true}
        />

        <FormInputField
            label="Founder Twitter Profile"
            id="twitter"
            value={businessFounder}
            setValue={setBusinessFounder}
            errorMessage={errorForm.twitter}
            isOptional={true}
        />
        <div className="mt-4 ml-auto flex flex-col items-center gap-4 md:flex-row">
            <button
                className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleSaveBusinessFounder}
            >
                Save
            </button>
        </div>
    </div>
}

export default BusinessFounderForm;