import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import axios from "axios";
import Card from "components/card";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { BusinessReportItem } from "interfaces/business/BusinessReport";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ModalSuccessSendTest from "./ModalSuccessSendTest";
import getViolations from "components/form/error/getViolations";

interface ModalSendAsTestProps {
    report: BusinessReportItem,
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
}

interface SendAsTest {
    businessReport?: string,
    subject?: string,
    body?: string,
    recipients?: string[]
}

interface SendAsTestFrom {
    businessReport?: string,
    subject?: string,
    body?: string,
    recipients?: string[]
}

const emptySendAsTest: SendAsTest = { body: null, subject: '', recipients: [] };

const ModalSendAsTest = ({ report, open, setOpen }: ModalSendAsTestProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [sendAsTest, setSendAsTest] = useState<SendAsTest | null>(emptySendAsTest);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [error, setError] = useState<SendAsTestFrom>({});

    const handlerSend = () => {
        const url = process.env.REACT_APP_API_URL + '/business_reports/email/send-test';

        axios.post(url, sendAsTest)
            .then(r => {
                onClose();
                setSuccessModal(true);
                setSendAsTest({ ...sendAsTest, body: null, subject: '', recipients: [] });
                setError({});
            })
            .catch(e => {
                const errorForm = getViolations<SendAsTestFrom>(e);
                setError(errorForm);
            });
    }

    const handleClose = () => {
        setError({});
        onClose();
    }

    useEffect(() => {
        if (open) {
            onOpen();
        } else {
            onClose();
        }
    }, [open]);

    useEffect(() => {
        onClose();
    }, []);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        setSendAsTest({ ...sendAsTest, businessReport: 'business_reports/' + report?.id.toString() })
    }, [report]);

    return <>
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay className="bg-[#000] !opacity-30" zIndex='1000' />
            <ModalContent
                containerProps={{ zIndex: '2000' }}
                className="!z-[1002] !m-auto !w-max min-w-[650px] !max-w-[85%] md:top-[12vh]"
            >
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w flex flex-col !z-[1004]">
                        <div className="mt-2">
                            <InputField
                                id="subject-line"
                                label="Email Subject Line"
                                placeholder="e.g [Business Name] Investor Update for November"
                                onChange={(e: any) => setSendAsTest({ ...sendAsTest, subject: e.target.value })}
                            />
                            <small className="text-red-500">{error.subject}</small>
                        </div>

                        <div className="mt-2">
                            <TextField
                                label="Body (optional text that will show in the email above the embedded Update Report) "
                                placeholder="Tell something about yourself in 150 characters!"
                                id="body"
                                cols={30}
                                rows={7}
                                onChange={(e: any) => setSendAsTest({ ...sendAsTest, body: e.target.value })}
                            />
                            <small className="text-red-500">{error.body}</small>
                        </div>

                        <div className="mt-2">
                            <InputField
                                id="recipients"
                                label="Recipients (comma separated)"
                                placeholder=""
                                onChange={(e: any) => setSendAsTest({ ...sendAsTest, recipients: e.target.value.replace(' ', '').split(',') })}
                            />
                            <small className="text-red-500">{error.recipients}</small>
                        </div>

                        <div className="mt-5 flex justify-between">
                            <div>
                                <div className="flex gap-2">
                                    <button
                                        onClick={handlerSend}
                                        className="linear text-blue-900 rounded-xl bg-gray-100 px-5 py-3 text-base font-medium transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                                    >
                                        Send Email
                                    </button>
                                    <button
                                        onClick={handleClose}
                                        className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
        <ModalSuccessSendTest open={successModal} setOpen={setSuccessModal} />
    </>
}

export default ModalSendAsTest;