import Card from "components/card";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";

interface Step4Props {
    setStep: Dispatch<SetStateAction<number>>;
}

const Step4 = ({ setStep }: Step4Props) => {
    const handleButton = () => setStep(4);

    return <Card extra={"w-50p px-[20px] py-[20px]  text-blue-900"}>
        <div className="w-full px-[8px]">
            <h4 className="text-xl font-bold  dark:text-white">
                Wow Your Investors every time!
            </h4>
        </div>
        <p className="mt-10 text-base">
            InvestorPulse is designed to benefit startup founders across various industries
            and stages of growth. Whether it's a seed-stage startup or a well-established company, InvestorPulse provides a scalable solution for delivering investor updates in a structured and engaging manner.
        </p>
        <p className="mt-5">
            Follow these simple steps:
        </p>
        <ol className="list-decimal md:list-decimal ml-5">
            <li>
                Send an Investor Update Report on the 4th of every month. <br />
                (Optional: We will send you a reminder via SMS or Email on the 1st)
            </li>
            <li>Use our “Update Report Creator” to expedite the process.</li>
            <li>
                Connect to your Quickbooks and/or Spreadsheets for live data. <br />
                (InvestorPulse will pre-populate your Update Reports with charts automatically)
            </li>
        </ol>


        <div className="mt-10 m-auto flex flex-col items-center gap-4 md:row">
            <Link
                to="/venture/add"
                className="linear rounded-xl bg-brand-500 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleButton}
            >
                Continue Business Setup
            </Link>
        </div>
    </Card>
}

export default Step4;