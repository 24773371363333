import { BusinessReportItem } from "interfaces/business/BusinessReport";
import PreviewLine from "./PreviewLine";
import IntegrationLine from "./IntegrationLine";
import FooterAddress from "./FooterAddress";
import FooterSocialMedia from "./FooterSocialMedia";
import ImageLine from "./ImageLine";

interface PreviewProps {
    report: BusinessReportItem
}

const Preview = ({ report }: PreviewProps) => {
    const shouldDisplayDefaultLogo = report.logo && report.logoMedia === undefined;
    const shouldDisplayDefaultChart = report.newCustomers;
 return <div>
            
        <ImageLine flag={report.logo} image={report.logoMedia} defaultContent={shouldDisplayDefaultLogo} />
      

        <PreviewLine flag={report.tagLine} value={report.tagLineText} />
        <PreviewLine flag={report.commentary} value={report.commentaryText} />
        <PreviewLine flag={report.highlights} value={report.highlightsText} />
        <PreviewLine flag={report.lowlights} value={report.lowlightsText} />

        <IntegrationLine
            flag={report.revenuePlan}
            reportApproved={report.approved}
            integration={report.revenueIntegration}
            text={report.revenueText}
        />
        
        <IntegrationLine
            flag={report.newCustomers}
            reportApproved={report.approved}
            integration={report.newCustomersIntegration}
            text={report.newCustomersText}
            defaultContent={shouldDisplayDefaultChart}  
        />

        <IntegrationLine
            flag={report.monthlyChurn}
            reportApproved={report.approved}
            integration={report.monthlyChurnIntegration}
            text={report.monthlyChurnText}
        />

        <PreviewLine flag={report.beginningPeriodCash} value={report.beginningPeriodCashText} />
        <PreviewLine flag={report.endPeriodCash} value={report.endPeriodCashText} />
        <IntegrationLine
            flag={report.kpi}
            reportApproved={report.approved}
            integration={report.kpiIntegration}
            text={report.kpiText}
        />

        <PreviewLine flag={report.productUpdates} value={report.productUpdatesText} />
        <ImageLine flag={report.pictureNewProduct} image={report.pictureNewProductMedia} />

        <PreviewLine flag={report.whereNeedHelp} value={report.whereNeedHelpText} />
        <PreviewLine flag={report.thankYou} value={report.thankYouText} />
        <FooterAddress report={report} />
        <FooterSocialMedia report={report} />
    </div>
}

export default Preview;