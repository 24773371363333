import axios from "axios";
import Card from "components/card";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WizardContext } from "components/wizard/WizardProvider";
import { Business, BusinessErrorForm } from "interfaces/business/Business";
import VentureForm from "./components/VentureForm";
import getViolations from "components/form/error/getViolations";


const host = process.env.REACT_APP_API_URL;

const CreateVenture = () => {
    const navigate = useNavigate();
    const { wizardBusinessName, setWizardBusinessName, wizardBusinessWebsite, setWizardBusinessWebsite } = useContext(WizardContext);
    const [business, setBusiness] = useState<Business>({ country: 'US', state: 'AL' });
    const [error, setError] = useState<BusinessErrorForm>({});

    const handleClickSave = async () => {
        await axios.post(host + '/businesses', business).then(response => {
            navigate('/');
        }).catch(e => {
            const errorForm = getViolations<BusinessErrorForm>(e);
            setError(errorForm);
        });
    }

    useEffect(() => {
        setBusiness({ ...business, name: wizardBusinessName || '', website: wizardBusinessWebsite || '' });
    }, [wizardBusinessName, wizardBusinessWebsite]);


    return <div className=" mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px]">
        <Card extra={"w-full 2xl:w-50p px-[20px] py-[20px]"}>
            {/* Header */}
            <div className="w-full px-[8px]">
                <h4 className="text-xl font-bold text-blue-900 dark:text-white">
                    Create Business / Venture
                </h4>
                <p className="mt-1 text-base text-gray-600">
                    Please create a new venture...
                </p>
            </div>

            <div className="mt-7 grid grid-cols-1 gap-3">
                <VentureForm business={business} setBusiness={setBusiness} error={error} />
            </div>
            
            <div className="mt-4 ml-auto flex flex-col items-center gap-4 md:flex-row">
                <button
                    className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={handleClickSave}
                >
                    Create
                </button>
            </div>
        </Card>
    </div>

}

export default CreateVenture;