import { Routes, Route } from "react-router-dom";

import MainLayout from "layouts/main";
import "assets/css/Plugins.css";
import { useState, useEffect } from "react";
import { useAuthContext } from "components/auth/AuthProvider";
import SignIn from "views/auth/signIn/SignIn";
import SignUp from "views/auth/signUp/SignUp";
import SignUpSuccess from "views/auth/signUp/SignUpSuccess";
import VerificationEmail from "views/auth/verification/VerificationEmail";
import ForgotPassword from "views/auth/forgotPassword/ForgotPassword";
import ResetPassword from "views/auth/forgotPassword/ResetPassword";
import WizardProvider from "components/wizard/WizardProvider";
import PrivacyPolicy from "views/main/others/404/privacy-policy";
import LockScreen from "views/main/others/LockScreen";
import PublicReport from "views/report";
import ScrollToTop from './components/scrollToTop/scrollToTop';

const App = () => {
  // Create a new context
  const [themeApp, setThemeApp] = useState<any>({
    "--background-100": "#FFFFFF",
    "--background-900": "#070f2e",
    "--shadow-100": "rgba(112, 144, 176, 0.08)",
    "--color-50": "#E9E3FF",
    "--color-100": "#C0B8FE",
    "--color-200": "#A195FD",
    "--color-300": "#8171FC",
    "--color-400": "#7551FF",
    "--color-500": "#422AFB",
    "--color-600": "#3311DB",
    "--color-700": "#2111A5",
    "--color-800": "#190793",
    "--color-900": "#11047A",
  });
  const [mini, setMini] = useState(false);

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    //eslint-disable-next-line
  }, [themeApp]);

  const auth = useAuthContext();

  if (!auth.isAuthorized()) {
    return (
      <Routes>
        <Route path="/" element={<SignIn/>} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup/success" element={<SignUpSuccess />} />
        <Route path="/verification/email/:id*" element={<VerificationEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/report/:slug" element={<PublicReport />} />
      </Routes>
    )
  }

  return (
    <WizardProvider>
     <ScrollToTop />
      <Routes>
        <Route></Route>
        <Route path="/report/:slug" element={<PublicReport />} />
        <Route
          path="/*"
          element={
            <MainLayout
              setMini={setMini}
              mini={mini}
              theme={themeApp}
              setTheme={setThemeApp}
            />
          }
        />
      </Routes>
    </WizardProvider>
  );
};

export default App;
