import Switch from 'components/switch';
import { BusinessReport } from 'interfaces/business/BusinessReport';
import { Dispatch, SetStateAction } from 'react';

interface CheckboxCardProps {
  name: string;
  field: string;
  description?: string;

  report: BusinessReport;
  setValue: Dispatch<SetStateAction<BusinessReport>>;
  addBottomLine?: boolean;
}
const CheckboxCard = ({
  name,
  field,
  report,
  description,
  setValue,
  addBottomLine,
}: CheckboxCardProps) => {
  const handleChange = () => {
    setValue({ ...report, [field]: !report[field as keyof typeof report] });
  };

  return (
    <div className="mt-5">
      <div className="flex items-center">
        <Switch
          color="blue"
          checked={!!report[field as keyof typeof report]}
          onChange={handleChange}
        />
        <p className="ml-2 font-bold text-blue-900 dark:text-white">{name}</p>
      </div>
      <div className="mb-3 ml-2 mt-3">{description}</div>
      {addBottomLine ? (
        <hr className="mb-2 mr-2 mt-7 h-[2px] bg-blue-900" />
      ) : (
        ''
      )}
    </div>
  );
};

export default CheckboxCard;
