import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/accordion";
import Card from "components/card";
import TextField from "components/fields/TextField";
import { ReportAssistantFormIntefrace, ReportAssistantIntefrace } from "interfaces/business/ReportAssistant";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";


interface FormPropsInterface {
    businessId: string;
    reportAssistant: ReportAssistantIntefrace;
    setReportAssistant: Dispatch<SetStateAction<ReportAssistantIntefrace>>;
    error: ReportAssistantFormIntefrace;
    setError: Dispatch<SetStateAction<ReportAssistantFormIntefrace>>;
    submitHandle: () => void;
    isLoading: boolean;
}
const AssistantForm = ({ businessId, reportAssistant, setReportAssistant, error, setError, submitHandle, isLoading}: FormPropsInterface) => {
    return <div className="h-full w-full rounded-xl">
        
        <Card extra={"px-[20px] py-[20px] mb-5"}>
            <TextField
                label="What happened last month? What where the Highlights?"
                placeholder="Enter keywords, bullets or write simple sentences, the AI will format, enhance and structure your content after clicking the submit button below.
            You will be able to edit the AI generated content in the next screen.&#13;&#13;Highlights examples:&#13;Customer churn is down to 2% from 3.2%, 200K in new Business last quarter, New Saleforce integration went live."
                id="highlights"
                cols={30}
                rows={8}
                state={error?.highlights ? 'error' : ''}
                onChange={(e: any) => setReportAssistant({ ...reportAssistant, highlights: e.target.value })}
                disabled={isLoading}
            />
            <small className="mt-2 text-red-500">{error?.highlights}</small>

            <TextField
                label="What are you currently working on? Product or Service Updates ?"
                placeholder="Enter keywords, bullets or write simple sentences, the AI will format, enhance and structure your content after clicking the submit button below.
            You will be able to edit the AI generated content in the next screen.&#13;&#13;Currently working on & Service/Product examples: &#13;1. South-East Asia expansion on track for 2024&#13;2. Learn Eurpean market, look for partnerships&#13;3. Set up Referral program"
                id="updates"
                cols={30}
                rows={8}
                state={error?.updates ? 'error' : ''}
                onChange={(e: any) => setReportAssistant({ ...reportAssistant, updates: e.target.value })}
                disabled={isLoading}
            />
            <small className="mt-2 text-red-500">{error?.updates}</small>

            <TextField
                label="What needs improvement or wasn’t ideal last month? Lowlights?"
                placeholder="Enter keywords, bullets or write simple sentences, the AI will format, enhance and structure your content after clicking the submit button below.
            You will be able to edit the AI generated content in the next screen.&#13;&#13;Needs improvements & Lowlights examples:&#13;Onboarding remains very slow, takes 4 weeks, it should take 4 days, frustrating for customers and for us."
                id="lowlights"
                cols={30}
                rows={8}
                state={error?.lowlights ? 'error' : ''}
                onChange={(e: any) => setReportAssistant({ ...reportAssistant, lowlights: e.target.value })}
                disabled={isLoading}
            />
            <small className="mt-2 text-red-500">{error?.lowlights}</small>
        </Card>
        <Card extra={"px-[20px] py-[20px] mb-5"}>
            <div className="grid grid-cols-2 justify-items-center">
                <button
                    className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white  duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={submitHandle}
                    disabled={isLoading}
                >
                    Submit & Generate
                </button>


                <Link
                    to={'/business/:businessId/reports/add'.replace(':businessId', businessId)}
                    className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                    Skip AI Assist
                </Link>

            </div>
            <p className="text-gray-700 text-[11px] mt-2 grid grid-cols-2 justify-items-center">Estimated processing time is 1 minute</p>

        </Card>
        <Card extra={"px-[20px] py-[20px]  mb-5"}>
            <Accordion className='w-full ' allowMultiple>
                <AccordionItem className='border-b border-gray-200 py-[17px]  dark:!border-white/10'>
                    <h2>
                        <AccordionButton className='flex justify-between '>
                            <span className='text-left font-bold text-navy-900 dark:text-white'>
                                Prompt Settings
                            </span>
                            <AccordionIcon className='text-left !text-navy-900 dark:!text-white' />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel className='text-left text-medium mt-2  !text-navy-900 dark:!text-white ' pb={4}>
                        <TextField
                            label="Prompt for: Opening Commentary (uses Highlights & Current)"
                            placeholder=""
                            defaultValue={reportAssistant?.promptOpeningCommentary}
                            id="promptOpeningCommentary"
                            cols={30}
                            rows={5}
                            state={error?.promptOpeningCommentary ? 'error' : ''}
                            onChange={(e: any) => setReportAssistant({ ...reportAssistant, promptOpeningCommentary: e.target.value })}
                        />
                        <small className="mt-2 text-red-500">{error?.promptOpeningCommentary}</small>
                        <div className='pt-[9px]'>
                            <TextField
                                label="Prompt for: Highlights "
                                placeholder=""
                                defaultValue={reportAssistant?.promptHighlights}
                                id="promptHighlights"
                                cols={30}
                                rows={5}
                                state={error?.promptHighlights ? 'error' : ''}
                                onChange={(e: any) => setReportAssistant({ ...reportAssistant, promptHighlights: e.target.value })}
                            />
                            <small className="mt-2 text-red-500">{error?.promptHighlights}</small>
                        </div>

                        <div className='pt-[9px]'>
                            <TextField
                                label="Prompt for: Current & ToDo"
                                placeholder=""
                                defaultValue={reportAssistant?.promptCurrent}
                                id="promptCurrent"
                                cols={30}
                                rows={5}
                                state={error?.promptCurrent ? 'error' : ''}
                                onChange={(e: any) => setReportAssistant({ ...reportAssistant, promptCurrent: e.target.value })}
                            />
                            <small className="mt-2 text-red-500">{error?.promptCurrent}</small>
                        </div>
                        <div className='pt-[9px]'>
                            <TextField
                                label="Prompt for: Lowlights"
                                placeholder=""
                                defaultValue={reportAssistant?.promptLowlights}
                                id="promptLowlights"
                                cols={30}
                                rows={5}
                                state={error?.promptLowlights ? 'error' : ''}
                                onChange={(e: any) => setReportAssistant({ ...reportAssistant, promptLowlights: e.target.value })}
                            />
                            <small className="mt-2 text-red-500">{error?.promptLowlights}</small>
                        </div>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Card>
    </div>
}

export default AssistantForm;