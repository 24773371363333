import { Dispatch, SetStateAction } from 'react';
import { BusinessReport } from 'interfaces/business/BusinessReport';
import Switch from 'components/switch';
import AssistantAILabel from './AssistantAILabel';
import TinyMCE from 'components/tinymce';

interface TextAreaCardProps {
  name: string;
  field: string;
  description?: string;

  report: BusinessReport;
  setValue: Dispatch<SetStateAction<BusinessReport>>;
  refer: any;
  init?: string;
  assistantAI?: boolean;
  addBottomLine?: boolean;
}

const TextAreaCard = ({
  name,
  field,
  description,
  report,
  setValue,
  refer,
  init,
  assistantAI,
  addBottomLine,
}: TextAreaCardProps) => {
  const handleChange = () => {
    setValue({ ...report, [field]: !report[field as keyof typeof report] });
  };

  const displayAsistantAI = () => {
    if (assistantAI) {
      return <AssistantAILabel />;
    }
  };

  return (
    <div className="mt-5">
      <div className="flex items-center">
        <Switch
          color="blue"
          checked={!!report[field as keyof typeof report]}
          onChange={handleChange}
        />
        <div className="flex w-full place-content-between">
          <p
            className={`ml-2 font-bold text-blue-900 dark:text-white ${
              assistantAI ? `pt-1` : ``
            }`}
          >
            {name}
          </p>
          {displayAsistantAI()}
        </div>
      </div>
      <div className="ml-2 mt-3">{description}</div>
      <div
        className={`duration-900 mt-5 text-blue-900 transition-all ${
          report[field as keyof typeof report] ? 'opacity-100' : 'hidden'
        }`}
      >
        <TinyMCE
          disable={!report[field as keyof typeof report]}
          init={init}
          refer={refer}
        />
      </div>
      {addBottomLine ? (
        <hr className="mb-2 mr-2 mt-7 h-[2px] bg-blue-900" />
      ) : (
        ''
      )}
    </div>
  );
};

export default TextAreaCard;
