import Card from "components/card";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";

interface Step3Props {
    setStep: Dispatch<SetStateAction<number>>;
}

const Step3 = ({ setStep }: Step3Props) => {
    const handleButton = () => setStep(4);

    return <Card extra={"w-50p px-[20px] py-[20px]"}>
        <div className="w-full px-[8px]">
            <h4 className="text-xl font-bold text-blue-900 dark:text-white">
                Get the Information YOU need. <br />
                Customize & Collaborate on Reporting with your Startups
            </h4>
        </div>
        <p className="mt-10 text-base">
            InvestorPulse improves founder-investor relationships by facilitating
            transparent and timely communication. It ensures that founders
            consistently provide comprehensive updates to their investors,
            fostering trust and alignment. By keeping investors well-informed,
            founders can also seek assistance and guidance when facing challenges,
            leading to stronger partnerships and increased support from investors.

        </p>

        <div className="mt-10 m-auto flex flex-col items-center gap-4 md:row">
            <Link
                to="/venture/add"
                className="linear rounded-xl bg-brand-500 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleButton}
            >
                Add a new Business Venture
            </Link>
        </div>
    </Card>
}

export default Step3;