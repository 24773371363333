import axios from "axios";
import Spinner from "components/spinner/Spinner";
import { Business, BusinessErrorForm } from "interfaces/business/Business";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "components/card";
import SolidSubtleAlert from "views/admin/main/others/notifications/components/SolidSubtleAlert";
import { AiFillExclamationCircle } from "react-icons/ai";
import ViewVentureForm from "./components/ViewVentureForm";
import BusinessFounderForm from "./components/BusinessFounderForm";

const host = process.env.REACT_APP_API_URL;

const ViewVenture = () => {
    const params = useParams();
    const [business, setBusiness] = useState<Business>({});
    const [loaded, setLoaded] = useState<boolean>(false);
    const [errorLoad, setErrorLoad] = useState<string | null>(null);
    const [error, setError] = useState<BusinessErrorForm>({});

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index: number) => {
        setToggleState(index);
    };

    useEffect(() => {
        if (params.businessId) {
            loadBusiness(parseInt(params.businessId));
            setLoaded(true);
            setErrorLoad(null)
        }
    }, []);

    const loadBusiness = async (id: number) => {
        const url = host + '/businesses/{id}'.replace('{id}', id.toString());

        axios.get(url).then(r => {
            setBusiness(r.data)
        }).catch(e => {
            console.log(e);
            if (e.response.status === 404) {
                setErrorLoad('Not found')
            } else {
                setErrorLoad("Something went wrong")
            }

        });
    }

    const displayTab = () => {
        switch (toggleState) {
            case 1: {
                return <ViewVentureForm
                    business={business}
                    setBusiness={setBusiness}
                    error={error}
                    setError={setError} />
            }
            case 2: {
                return <BusinessFounderForm businessId={business.id}/>
            }
            case 3: {
                return <p>MVP v2</p>
            }
        }
    }
    if (!loaded) {
        return <Spinner />
    }

    if (errorLoad !== null) {
        return <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px]">
            <SolidSubtleAlert
                title="Error"
                description={errorLoad}
                icon={<AiFillExclamationCircle />}
                iconColor="text-white"
                closeBg="hover:bg-white/20 text-white"
                bg="bg-red-500 dark:!bg-red-400"
                solid="solid"
            />
        </div>
    }

    return <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px]">
        <Card extra={"w-50p px-[20px] py-[20px]"}>
            <div className="mb-1 flex w-full flex-col items-center rounded-xl xl:mb-3">
                <div className="flex w-full justify-start gap-8 overflow-hidden text-3xl">
                    <div
                        className={
                            toggleState === 1
                                ? " flex items-center gap-3 border-b-[4px] border-brand-500 pb-3 hover:cursor-pointer dark:border-brand-400"
                                : "flex items-center gap-3 border-b-[4px] border-white pb-3 hover:cursor-pointer dark:!border-navy-800"
                        }
                        onClick={() => toggleTab(1)}
                    >
                        <p className="text-[18px] font-medium text-blue-900 dark:text-white">
                            Business Information
                        </p>
                    </div>

                    <div
                        className={
                            toggleState === 2
                                ? " flex items-center gap-3 border-b-[4px] border-brand-500 pb-3 hover:cursor-pointer dark:border-brand-400"
                                : "flex items-center gap-3 border-b-[4px] border-white pb-3 hover:cursor-pointer dark:!border-navy-800"
                        }
                        onClick={() => toggleTab(2)}
                    >
                        <p className="text-[18px] font-medium text-blue-900 dark:text-white">
                            Founder Information
                        </p>
                    </div>

                    <div
                        className={
                            toggleState === 3
                                ? " flex items-center gap-3 border-b-[4px] border-brand-500 pb-3 hover:cursor-pointer dark:border-brand-400"
                                : "flex items-center gap-2 border-b-[4px] border-white pb-3 hover:cursor-pointer dark:!border-navy-800"
                        }
                        onClick={() => toggleTab(3)}
                    >
                        <p className="text-[18px] font-medium text-blue-900 dark:text-white">
                            Invite Collaborators
                        </p>
                    </div>
                </div>
            </div>
            <div className="4xl:grid-cols-[2.95fr 1fr] grid">
                {/* left side */}
                <div className="4xl:col-start-1 4xl:col-end-2 4xl:row-start-1 4xl:row-end-2">
                    {/* paragraphs */}
                    <div className="mt-10 pr-4 text-lg text-gray-700 dark:text-white">
                        {displayTab()}
                    </div>
                </div>
            </div>
        </Card>
    </div>
}

export default ViewVenture;