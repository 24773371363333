import Card from "components/card";
import InputField from "components/fields/InputField";
import { Dispatch, SetStateAction } from "react";

interface Step1Props {
    name: string,
    setName: Dispatch<SetStateAction<string>>;
    website: string,
    setWebsite: Dispatch<SetStateAction<string>>
    setStep: Dispatch<SetStateAction<number>>
}

const Step1 = ({ name, setName, website, setWebsite, setStep }: Step1Props) => {

    const handleButton = () => {
        setStep(1);
    }

    const handleName = (e:any) => setName(e.target.value);
    const handleWebsite = (e:any)=> setWebsite(e.target.value);

    return <>
        <Card extra={"w-50p px-[20px] py-[20px]"}>
            <div className="w-full px-[8px]">
                <h4 className="text-xl font-bold text-blue-900 dark:text-white">
                    We successfully created your Account
                </h4>
                <p className="mt-1 text-base text-gray-600">
                    Please enter your company details so we can customize your account
                </p>
            </div>

            <div className="mt-7 grid grid-cols-1 gap-3">
                <InputField
                    label="Business Name"
                    placeholder="Business Name"
                    id="business"
                    value={name}
                    onChange={handleName}
                />

                <InputField
                    label="Business website"
                    placeholder="Business website"
                    id="website"
                    value={website}
                    onChange={handleWebsite}
                />
            </div>

            <div className="mt-4 ml-auto flex flex-col items-center gap-4 md:flex-row">
                <button
                    className="linear rounded-xl bg-brand-500 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={handleButton}
                >
                    Next
                </button>
            </div>
        </Card>
    </>
}

export default Step1;