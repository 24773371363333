import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Card from "components/card";
import { GoogleOAurhToken } from "interfaces/oauth/GoogleOAuthToken";
import { Dispatch, SetStateAction, useEffect } from "react";

interface AlertGoogleOAuthProps {
    googleOAuthToken: GoogleOAurhToken,
    setGoogleOAuthToken: Dispatch<SetStateAction<GoogleOAurhToken>>,
    active: boolean|null
}

const AlertGoogleOAuth = ({ googleOAuthToken, setGoogleOAuthToken, active }: AlertGoogleOAuthProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const updateToken = async (code: string) => {
        const url = process.env.REACT_APP_API_URL + '/google_o_auth_tokens/{id}'
            .replace('{id}', googleOAuthToken.id.toString());

        axios.put(url, { code: code }).then(response => setGoogleOAuthToken(response.data));
    }

    const googleLogin = useGoogleLogin({
        scope: "https://www.googleapis.com/auth/drive",
        onSuccess: async tokenResponse => {
            updateToken(tokenResponse.code)
        },
        flow: 'auth-code'
    });

    const handleClick = () => {
        googleLogin();
    };

    useEffect(() => {
        if (active === false) {
            onOpen();
        } else{
            onClose();
        }
    }, [active]);

    return <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" zIndex='1000' />
        <ModalContent
            containerProps={{ zIndex: '2000' }}
            className="!z-[1002] !m-auto !w-max min-w-[550px] !max-w-[50%] md:top-[12vh]"
        >
            <ModalBody>
                <Card extra="px-[30px] pt-[35px] pb-[40px] flex flex-col !z-[1004]">
                    <h1 className="mb-4 text-2xl">Your Google Auth is expired</h1>
                    <p>
                        To ensure continued access to your Google Sheets integration, kindly renew your authorization. This authorization is valid for a duration of 7 days, but please note that this limitation is only applicable to the demo/beta version. The final product will not be subject to this restriction.
                    </p>
                    <div className="flex justify-between mt-5">
                        <button
                            className="linear text-blue-900 rounded-xl bg-gray-100 px-5 py-3 text-base font-medium transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                            onClick={handleClick}
                        >
                            Refresh
                        </button>
                    </div>
                </Card>
            </ModalBody>
        </ModalContent>
    </Modal>
}

export default AlertGoogleOAuth;