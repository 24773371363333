import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/models/dom';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/preview';

import { Editor } from "@tinymce/tinymce-react"

interface TinyMCEProps {
    disable: boolean;
    init?: string;
    refer: any;
}

const TinyMCE = ({ disable, init, refer }: TinyMCEProps) => {
    return <Editor
        disabled={disable}
        initialValue={init}
        onInit={(evt, editor) => refer.current = editor}
        init={{
            height: 300,
            menubar: false,
            plugins:
                'advlist autolink lists link image charmap anchor searchreplace visualblocks code fullscreen preview insertdatetime media table code wordcount',
            toolbar: 'undo redo | formatselect | blocks ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | past | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; color: #2B3674; font-size:14px }'
        }}
    />
}

export default TinyMCE;