import { useState } from "react";

const AUTOSAVE_DEBOUNCE_TIME = 1000;

interface useAutoSaveProps<T> {
    onSave: (data: T) => void;
}

const useAutoSave = <T,>({ onSave }: useAutoSaveProps<T>) => {
    const [autoSaveTimer, setAutoSaveTimer] = useState(null);

    const dispatchAutoSave = (data: T) => {
        clearTimeout(autoSaveTimer);

        const timer = setTimeout(() => onSave(data), AUTOSAVE_DEBOUNCE_TIME);

        setAutoSaveTimer(timer);
    };

    const triggerManualSave = (data: T) => {
        clearTimeout(autoSaveTimer);
        onSave(data);
    };

    return { dispatchAutoSave, triggerManualSave };
};

export default useAutoSave;