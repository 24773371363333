import axios, { AxiosResponse } from "axios";

const loadBusinessReport = async (id: number, success: (r: AxiosResponse) => void) => {
    const url = process.env.REACT_APP_API_URL + '/business_reports/{id}'
        .replace('{id}', id.toString());

    await axios.get(url).then(r => {
        success(r);
    });
}

const loadBusinessReportBySlug = async (slug: string, success: (r: AxiosResponse) => void) => {
    const url = process.env.REACT_APP_API_URL + '/business_reports/{slug}'
        .replace('{slug}', slug);

    await axios.get(url).then(r => {
        success(r);
    });
}

const getBusinessReportSlug = async (id: number, success: (r: AxiosResponse) => void) => {
    const url = process.env.REACT_APP_API_URL + '/business_reports/{id}/slug'
        .replace('{id}', id.toString());

    await axios.get(url).then(r => {
        success(r);
    });
}

export { loadBusinessReport, loadBusinessReportBySlug, getBusinessReportSlug }