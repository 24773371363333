import { createContext, useContext, useEffect } from "react";
import useAuth, { User, UserData } from "./useAuth";
import { jwtInterceptor } from "./jwtInterceptor";

interface AuthProviderInterface {
    user: User | null,
    signIn: (data: UserData) => void,
    signOut: () => void,
    getEmail: () => string,
    isAuthorized: () => boolean,
    isFirstLogin: () => boolean,
    updateFirstLogin: () => void
};

const AuthContext = createContext<AuthProviderInterface>(
    {
        user: null,
        signIn: () => { },
        signOut: () => { },
        getEmail: () => '',
        isAuthorized: () => false,
        isFirstLogin: () => false,
        updateFirstLogin: () => { }
    }
);

const useAuthContext = () => useContext(AuthContext)

interface AuthProviderProps {
    children: React.ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
    const auth = useAuth();
    jwtInterceptor(auth);

    return <AuthContext.Provider value={auth}>
        {children}
    </AuthContext.Provider>
}

export { useAuthContext, AuthProvider }