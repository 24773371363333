import { useState } from "react";
import { TableColumn } from "react-data-table-component";

interface useTableInterface<T> {
    dataHanlder?: (page: number) => Promise<any>;
}

const useTableData = <T,>({ dataHanlder }: useTableInterface<T>) => {
    const [data, setData] = useState<T[]>([]);
    const [columns, setColumns] = useState<TableColumn<T>[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const load = async (page: number = 1) => {
        setLoading(true);
        const response = await dataHanlder(page);

        setData(response['hydra:member']);
        setTotalItems(response['hydra:totalItems']);
        setLoading(false);
    };

    return { data, setData, columns, setColumns, totalItems, setTotalItems, loading, setLoading, load }
}

export default useTableData;