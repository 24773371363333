import { Parser } from "html-to-react";
import "assets/css/Preview.css";

interface PreviewLineProps {
    flag: boolean,
    value: string
}

const PreviewLine = ({ flag, value }: PreviewLineProps) => {
    return <div className="mb-5 preview">
        {flag ? Parser().parse(value) : ''}

    </div>
}
export default PreviewLine;