import { useEffect, useState } from "react";
import { BusinessReport, BusinessReportErrorForm } from "interfaces/business/BusinessReport";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import getViolations from "components/form/error/getViolations";
import BusinessReportForm from "./components/BusinessReportForm";

const Report = () => {
    const { businessId } = useParams();
    const [report, setReport] = useState<BusinessReport>({});
    const [error, setError] = useState<BusinessReportErrorForm>({});
    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    const handleChange = async (report: BusinessReport) => {
        const url = process.env.REACT_APP_API_URL + '/business_reports';

        await axios.post(url, report)
            .then(r => {
                navigate('/business/:businessId/report/:reportId'.replace(':businessId', businessId).replace(':reportId', r.data.id))
            })
            .catch(e => {
                const errorForm = getViolations<BusinessReportErrorForm>(e);
                setError(errorForm);
            });
    }

    useEffect(() => {
        setReport({
            ...report,
            logo: true,
            newCustomers: true

        });
    }, [businessId])

    return <div className="mt-3 h-full w-full">
        <div className="mb-5 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-2">
            <BusinessReportForm
                businessId={parseInt(businessId)}
                report={report}
                setReport={setReport}
                error={error}
                handleChange={handleChange}
            />
        </div>
    </div>
}

export default Report;