interface FormSelectFieldProps {
    label: string,
    id: string,
    value: string,
    onChange: (e: any) => void,
    render: () => JSX.Element[],
    className?: string
}

const FormSelectField = ({ label, id, value, onChange, render, className }: FormSelectFieldProps) => {
    return <div className={className}>
        <div>
            <label
                htmlFor={id}
                className="ml-3 mb-2 text-sm font-bold text-blue-900 dark:text-white"
            >
                {label}
            </label>
            <div className="w-full rounded-xl border border-gray-200 p-3 mt-2 text-sm font-normal text-gray-600 outline-none dark:!border-white/10 sm:w-full md:w-full">
                <select
                    className="text-lightFourth w-full rounded-xl pr-4 text-sm outline-none bg-white dark:bg-navy-800 dark:text-white"
                    name=""
                    id={id}
                    value={value}
                    onChange={onChange}
                >
                    {render()}
                </select>
            </div>
        </div>
    </div>
}

export default FormSelectField;