import Spinner from "components/spinner/Spinner";
import { MouseEventHandler } from "react";

interface ButtonProps {
  type: 'brand' | 'gray' | 'navy' | 'green' | 'red' | 'blue';
  label: string;
  loading?: boolean;
  completed?: boolean;
  disabled?: boolean
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Button = ({ type, label, loading, completed, disabled, className, onClick }: ButtonProps) => {
  if (completed) {
    type = 'green';
  }
  let bgColorClass;
  if (type === 'blue') {
    bgColorClass = 'bg-blue-900'
  }
  else if (type === 'gray') {
    bgColorClass = `bg-${type}-200`;
  }
  else {
    bgColorClass = `bg-${type}-500`;
  }
  let classes = [
    'rounded-xl',
    bgColorClass,
    'px-5',
    'pl-4',
    'py-3',
    `pl-${loading ? 8 : 3}`,
    'text-base',
    'font-medium',
    `text-${type === 'gray' ? 'blue-900' : 'white'}`,
    'transition',
    'relative',
    'duration-200',
    `hover:bg-${type}-600`,
    `active:bg-${type}-700`,
    `dark:bg-${type}-400`,
    'dark:text-white',
    `dark:hover:bg-${type}-300`,
    `dark:active:bg-${type}-200`,
     label.length < 6 ? 'w-20' : '',
  ];

  return (
    <button onClick={onClick} className={classes.join(' ') + ' ' + (className ? className : '')}>
      {loading ? <Spinner className="absolute top-4 -mt-0.5 left-2" /> : null}
      {label}
    </button>
  );
};

export default Button;
export {};
