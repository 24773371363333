import Card from "components/card";
import Input from "components/fields/Input";
import { ChartData } from "interfaces/business/ChartData";
import { ChangeEvent, useMemo } from "react";
import { Line } from "react-chartjs-2";

interface ChartCardProps {
    title: string;
    textHeader?: (sum: string) => JSX.Element;
    startLabel?: string;
    growthType?: string;
    growthLabel?: string;
    churnLabel?: string;
    data: ChartData;
    setData?: (value: ChartData) => void;
    generateData: () => number[];
    sumType: 'sum' | 'last';
}

const ChartCard = ({ title, startLabel, growthType, growthLabel, churnLabel, textHeader, data, setData, generateData, sumType }: ChartCardProps) => {
    const sumNumber = (): number => {
        let sum: number = 0;

        for (const i of generateData()) {
            sum += i;
        }

        return sum;
    }

    const showLast = (): number => {
        const data = generateData();

        let last = data.length;
        return data[last - 1];
    }

    const options = () => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top' as const,
                    display: false
                }
            }
        };
    };

    const lineData = () => {
        const labels: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

        return {
            labels,
            datasets: [
                {
                    label: 'Users',
                    data: data !== undefined ? generateData() : [],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ]
        };
    };

    const displaySumNumber = useMemo<string>(() => {
        const type = sumType === 'last' ? showLast() : sumNumber();

        const sum = data !== undefined ? type : 0;

        return sum > 0 ? Math.round(sum).toString() : 'XX';
    }, [data]);

    return <Card extra={"mt-5 px-[20px] py-[20px]"}>
        <div className="flex items-top justify-end">
            <div className="basis-2/6">
                <h2 className="text-xl">{title}</h2>
                <div className="mt-5">
                    {typeof textHeader == 'function' ? textHeader(displaySumNumber) : ''}
                </div>

                <div className="mt-2">
                    {startLabel !== undefined ?
                        <div className="flex mb-2 items-center">
                            <div className="basis-1/2">
                                <p>{startLabel}</p>
                            </div>
                            <div>
                                <Input
                                    id="start-users"
                                    type="number"
                                    value={data?.start}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setData({ ...data, start: parseInt(e.target.value) })}
                                />
                            </div>
                        </div>
                        : <></>}
                    {growthType !== undefined ?
                        <div className="flex mb-2 items-center">
                            <div className="basis-1/2">
                                <p>{growthType}</p>
                            </div>
                            <div className="mr-2">
                                <p>Linear</p>
                            </div>
                        </div>
                        : <></>}
                    {growthLabel !== undefined ?
                        <div className="flex mb-2 items-center">
                            <div className="basis-1/2">
                                <p>{growthLabel}</p>
                            </div>
                            <div>
                                <Input
                                    id="growth-users"
                                    type="number"
                                    value={data?.growth}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setData({ ...data, growth: parseInt(e.target.value) })}
                                />
                            </div>
                        </div>
                        : <></>}
                    {churnLabel !== undefined ?
                        <div className="flex mb-2 items-center">
                            <div className="basis-1/2">
                                <p>{churnLabel}</p>
                            </div>
                            <div>
                                <Input
                                    id="charn-users"
                                    type="number"
                                    value={data?.churn}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setData({ ...data, churn: parseFloat(e.target.value) })}
                                />
                            </div>
                        </div>
                        : <></>}
                </div>
            </div>
            <div className="grow ">
                <div className="flex justify-end h-96">
                    <Line options={options()} data={lineData()} />
                </div>
            </div>
        </div>
    </Card>
}

export default ChartCard;