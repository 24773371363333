import Card from "components/card";
import DataTable from "react-data-table-component";
import { useTableContext } from "./TableProvider";

const Table = () => {
    const context = useTableContext();

    const handlePageChange = (page: number) => {
        context.load(page);
    }

    return <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
            <DataTable
                columns={context?.columns}
                data={context?.data}
                responsive={true}
                pagination
                paginationServer
                progressPending={context.loading}
                paginationPerPage={10}
                paginationTotalRows={context?.totalItems}
                onChangePage={handlePageChange}
                className="w-full"
            />
        </div>
    </Card>
}

export default Table;