import { BusinessReportItem } from "interfaces/business/BusinessReport";

interface FooterSocialMediaProps {
    report: BusinessReportItem,
}

const FooterSocialMedia = ({ report }: FooterSocialMediaProps) => {
    if (report.footerBusinessSocialMedia) {
        return <div className="mt-2 text-center">
            {report.business.linkedinUrl ? <p><a href={report.business.linkedinUrl}>Linkedin</a></p> : ''}
            {report.business.twitterUrl ? <p><a href={report.business.twitterUrl}>Twitter</a></p> : ''}
        </div>
    }
}

export default FooterSocialMedia;