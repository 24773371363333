import axios from "axios";
import Spinner from "components/spinner/Spinner";
import { ChartData } from "interfaces/business/GoogleSheetsData";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { BusinessReportIntegration } from "interfaces/business/BusinessReportIntegration";
import PreviewLine from "./PreviewLine";
import { BusinessReport } from '../../../../../interfaces/business/BusinessReport';
import defaultChart from "assets/img/crm/defaultChart.png";


interface NewCustomersProps {
    flag: boolean;
    report?: BusinessReport,

    reportApproved: boolean;
    integration: BusinessReportIntegration;
    text: string | null;
    defaultContent?: boolean

}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const IntegrationLine = ({ flag, reportApproved, report, integration, text, defaultContent }: NewCustomersProps) => {
    const [data, setData] = useState<ChartData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const loadDataFromGoogleSheets = async () => {
        if (
            integration !== undefined &&
            integration.googleSheets !== undefined &&
            typeof integration.googleSheets !== 'string'
        ) {
            const url = process.env.REACT_APP_API_URL + '/google-sheets/{googleSheetsId}/data'
                .replace('{googleSheetsId}', integration.googleSheets.id.toString());

            try {
                const response = await axios.get(url);
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error loading data from Google Sheets:", error);
                setLoading(false);
            }
        }
    };

    const lineData = () => {
        const labels = data?.headers ?? [];

        return {
            labels,
            datasets: [
                {
                    label: data?.name,
                    data: data?.data ?? [],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ]
        };
    };

    const options = () => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top' as const,
                },
                title: {
                    display: !!integration.googleSheetsDescription,
                    text: integration.googleSheetsDescription ?? ''
                },
            }
        };
    };

    const displayIntegration = () => {
        if (integration?.googleSheets !== undefined) {
            return <>{loading ? <Spinner /> : <Line data={lineData()} options={options()} />}</>
        }

        return <></>
    }

    const displayMedia = () => {
        if (integration?.mediaObject !== undefined && typeof integration.mediaObject !== 'string') {
            return (
                <div className="flex items-center justify-center">
                    <img src={integration.mediaObject.contentUrl} alt={integration.mediaObjectDescription ?? ''} />
                    {/* <small className="text-center">{integration.mediaObjectDescription ?? ''}</small> */}
                </div>
            );
        }
        if (defaultContent !== undefined && defaultContent === true) {
            return (
                <div className="flex items-center justify-center">
                    <img src={defaultChart} />
                </div>
            )
        }
    }

    useEffect(() => {
        if (!integration) {
            return;
        }

        if (reportApproved && integration.businessReportData) {
            setData(integration.businessReportData);
            setLoading(false);
        } else {
            loadDataFromGoogleSheets();
        }
    }, [integration, reportApproved]);

    if (flag === false) {
        return <></>;
    }
    else {
        return (<>
            <>{displayIntegration()}</>
            <>{displayMedia()}</>

            <><PreviewLine flag={text !== null} value={text} /></>
        </>)
    }
};

export default IntegrationLine;
