import Spinner from "components/spinner/Spinner";
import { BusinessReportItem } from "interfaces/business/BusinessReport";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadBusinessReportBySlug } from "services/business/BusinessReport";
import Preview from "views/main/report/preview/components/Preview";

const PublicReport = () => {
    const { slug } = useParams();
    const [report, setReport] = useState<BusinessReportItem | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const loadReport = async () => {
        if (slug) {
            loadBusinessReportBySlug(slug, r => {
                setReport(r.data);
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        loadReport();
    }, [slug]);

    return <div className="flex flex-row justify-center">
        <div className="basis-4/5 md:basis-4/5 lg:basis-3/5 xl:basis-2/5 2xl:basis-2/5">
            {loading ? <Spinner /> : <Preview report={report} />}
        </div>
    </div>
}

export default PublicReport;