import axios from "axios";
import Card from "components/card";
import InputField from "components/fields/InputField";
import Spinner from "components/spinner/Spinner";
import NoAuthCentered from "layouts/auth/types/NoAuthCentered";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

const ResetPassword = () => {
  const [password, setPassword] = useState<string>('');
  const handlePassword = (e: any) => setPassword(e.target.value);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>('');
  const [changed, setChanged] = useState<boolean>(false);

  const handleReset = async () => {
    setLoading(true);
    setError('');

    const token = searchParams.get('token');
    const url = process.env.REACT_APP_API_URL + '/users/reset-password';

    await axios.post(url, { password: password, token: token })
      .then(r => { setLoading(false); setPassword(''); setChanged(true) })
      .catch(e => {
        setLoading(false);
        setPassword('');
        if (e.response.data?.violations) {
          setError(e.response.data?.violations[0].message)
        } else {
          setError("Your link is expired")
        }
      });

  }

  const displayForm = () => {
    if (!changed) {
      return <>
        <InputField
          variant="auth"
          label="Password"
          placeholder="Your new password"
          id="password"
          type="password"
          value={password}
          onChange={handlePassword}
        />
        {loading ? <Spinner /> : ''}
        <small className="text-red-500 text-center">{error}</small>
        {/* button */}
        <button
          className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleReset}
        >
          Reset
        </button>
      </>
    }

    return <Link
      to="/"
      className="linear mt-4 w-full text-center rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
    >
      Go to login!
    </Link>
  }

  return (
    <NoAuthCentered
      maincard={
        <Card
          extra={
            "max-w-[405px] md:max-w-[550px] h-max mt-32 md:mt-[120px] mx-2.5 md:mx-auto mb-auto pb-2.5 pt-8 md:pt-2.5 px-4 md:!p-[50px]"
          }
        >
          <p className="mb-2 text-4xl font-bold text-blue-900 dark:text-white">
            Reset Your Password
          </p>
          <p className="mt-[10px] ml-1 mb-8 text-base text-gray-600">
            Please enter your new Password.
          </p>
          {/* Email */}
          {displayForm()}
          <div className="mt-3">
            <span className="text-sm font-medium text-blue-900 dark:text-gray-500">
              Already a member?
            </span>
            <Link
              className="ml-1 text-sm font-medium text-blue-900 hover:text-brand-500 dark:text-white"
              to="/"
            >
              Sign In
            </Link>
          </div>
        </Card>
      }
    />
  );
}

export default ResetPassword;