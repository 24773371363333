import axios from 'axios';
import Table from 'components/datatable/Table';
import { useTableContext } from 'components/datatable/TableProvider';
import { BusinessReport } from 'interfaces/business/BusinessReport';
import { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import ModalBox from '../../../../../components/base/ModalBox';
import Button from '../../../../../components/fields/Button';

interface TableReportsProps {
  businessId: string;
}
const TableReports = ({ businessId }: TableReportsProps) => {
  const table = useTableContext();
  const [selectedRow, setSelectedRow] = useState<BusinessReport | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const columns: TableColumn<BusinessReport>[] = [
    {
      name: 'Montly Update',
      selector: (row) => row.name,
    },
    {
      name: 'Status',
      selector: (row) => (row.approved ? 'Approved' : 'Pending'),
    },
    {
      name: 'Actions',
      cell: (row) => linkToReport(row),
    },
    {
      name: 'Created',
      selector: (row) => displayDate(row),
    },
    {
      name: '',
      cell: (row) =>
        row.approved ? (
          'Approved'
        ) : (
          <button onClick={() => handleDeleteClick(row)}>Delete</button>
        ),
    },
  ];

  const handleDeleteClick = (row: BusinessReport) => {
    setSelectedRow(row);
    setModalVisible(true);
  };

  const linkToReport = (report: BusinessReport) => {
    if (report.approved) {
      const url = '/business/:businessId/report/:reportId'
        .replace(':businessId', businessId)
        .replace(':reportId', report.id.toString());

      return <Link to={url}> Email or Share Link</Link>;
    } else {
      const url = '/business/:businessId/report/edit/:reportId'
        .replace(':businessId', businessId)
        .replace(':reportId', report.id.toString());

      return <Link to={url}>Edit</Link>;
    }
  };

  const displayDate = (report: BusinessReport): string => {
    const date = new Date(report.createdAt);

    const formatter = new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const formattedDate = formatter.format(date);

    return formattedDate;
  };

  const deleteHandler = (report: BusinessReport) => {
    const url =
      process.env.REACT_APP_API_URL +
      '/business_reports/:id'.replace(':id', report.id.toString());

    axios.delete(url).then((r) => table.load(1));
  };

  useEffect(() => {
    table.setColumns(columns);
    table.load(1);
  }, [businessId]);

  console.log(selectedRow);
  return (
    <>
      <Table />
      {selectedRow && (
        <ModalBox
          body={
            <>
              <div className="px-2 py-4 text-gray-800">
                Are you sure you want to delete <b>{selectedRow.name}</b> ?
              </div>
              <div />
            </>
          }
          action={
            <>
              <Button
                type="red"
                label="Delete"
                onClick={() => {
                  deleteHandler(selectedRow);
                  setModalVisible(false);
                }}
              />
            </>
          }
          header="Delete Update Draft Confirmation"
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          typeCloseButton="blue"
        />
      )}
    </>
  );
};

export default TableReports;
