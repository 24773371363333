import axios from 'axios';
import { TableProvider } from 'components/datatable/TableProvider';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import TableReports from './components/TableReports';
import Card from '../../../../components/card';
import { ExplainerText } from '../../../../components/explainerText/explainerText';

const ReportDashboard = () => {
  const { businessId } = useParams();

  const loadReports = async (page: number) => {
    if (businessId) {
      let url =
        process.env.REACT_APP_API_URL +
        '/businesses/:bid/reports'.replace(':bid', businessId);

      if (page > 1) {
        url += '?page=' + page;
      }

      const response = await axios.get(url);

      return response.data;
    }
  };

  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px]">
      <ExplainerText text='Below is a list of approved and pending updates for your chosen
          business or venture.' />
      <div>
        <TableProvider dataHanlder={loadReports}>
          <TableReports businessId={businessId} />
        </TableProvider>
      </div>
      <div className="flex justify-end">
        <Link
          className="linear rounded-xl bg-blue-900 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          to={'/business/' + businessId + '/assistant-report/add'}
        >
          Create a new Update Report
        </Link>
      </div>
    </div>
  );
};

export default ReportDashboard;
