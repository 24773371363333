import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { useEffect } from "react";

interface ModalShareLinkProps {
    url: string,
    open: boolean
}

const ModalShareLink = ({ url, open }: ModalShareLinkProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (open) {
            onOpen();
        } else {
            onClose();
        }
    }, [open])

    useEffect(() => {
        onClose();
    }, [])

    return <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" zIndex='1000' />
        <ModalContent
            containerProps={{ zIndex: '2000' }}
            className="!z-[1002] !m-auto !w-max min-w-[550px] !max-w-[85%] md:top-[12vh]"
        >
            <ModalBody>
                <Card extra="px-[30px] pt-[35px] pb-[40px] max-w flex flex-col !z-[1004]">

                    <InputField
                        id="url"
                        label="URL"
                        placeholder=""
                        value={url}
                    />

                    <div className="mt-5 flex justify-between">
                        <div>
                            <div className="flex gap-2">
                                <button
                                    onClick={onClose}
                                    className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                >
                                    Close
                                </button>
                                <button
                                    className="linear text-blue-900 rounded-xl bg-gray-100 px-5 py-3 text-base font-medium transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                                    onClick={() => { navigator.clipboard.writeText(url) }}
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                    </div>
                </Card>
            </ModalBody>
        </ModalContent>
    </Modal>
}

export default ModalShareLink;