import axios from "axios";
import Card from "components/card";
import Spinner from "components/spinner/Spinner";
import NoAuthCentered from "layouts/auth/types/NoAuthCentered";
import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

function VerificationEmail() {

  const params = useParams();
  const [searchParams] = useSearchParams();
  const [send, setSend] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const confirmEmail = async (id: number, expires: number, signature: string, token: string) => {
    const url = process.env.REACT_APP_API_URL + '/users/{id}/verify?expires={expires}&signature={signature}&token={token}'
      .replace('{id}', id.toString())
      .replace('{expires}', expires.toString())
      .replace('{signature}', encodeURIComponent(signature))
      .replace('{token}', encodeURIComponent(token));

    await axios.get(url).then(r => {
      setSend(true);
    }).catch(e => {
      setSend(true);
      setError('Somethinng went wrong...')
    });
  }

  useEffect(() => {
    const id = params.id;
    const expires = searchParams.get('expires');
    const signature = searchParams.get('signature');
    const token = searchParams.get('token');

    confirmEmail(parseInt(id), parseInt(expires), signature, token);
  });

  const spinner = () => {
    if (!send) {
      return <Spinner/>
    }

    if (!error) {
      return <Link
        className="linear text-center mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        to="/"
      >
        Go to login page!
      </Link>
    }

    return <p className="text-red-500">{error}</p>
  }

  return (
    <NoAuthCentered
      maincard={
        <Card extra="max-w-[405px] md:max-w-[510px] h-max mx-2.5 md:mx-auto mt-12 mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <h3 className="text-4xl font-bold text-blue-900 dark:text-white">
            Email Confirmed
          </h3>

          {spinner()}

        </Card>
      }
    />
  );
}

export default VerificationEmail;
