import { useDropzone } from "react-dropzone";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { MediaObject } from "interfaces/MediaObject";

const DropZonefile2 = (
  props: {
    content: JSX.Element,
    setFile: Dispatch<SetStateAction<File>>,
    disabled: boolean,
    media: string | MediaObject,
    description?: boolean,
    handleRemove?: () => void,
    handleUpdateDescription?: (event: any) => void
    valueDesciption?: string
  }
) => {
  const { content } = props;
  const [image, setImage] = useState(null)

  const dropHandler = useCallback((acceptedFiles: File[]) => {
    // silently ignore if nothing to do
    if (acceptedFiles.length < 1) {
      return;
    }

    const file = acceptedFiles[0];
    props.setFile(file);
    setImage(URL.createObjectURL(file));
  }, []);

  const remove = () => {
    setImage(null);
    props.setFile(null);
    props.handleRemove();
  }

  useEffect(() => {
    if (typeof props.media === 'string') {
      setImage(props.media);
    } else {
      if (props.media !== undefined && props.media !== null) {
        setImage(props.media.contentUrl);
      }
    }
  }, [props.media])

  const { getRootProps, getInputProps } = useDropzone({ onDrop: dropHandler, disabled: !props.disabled });

  return (
    <div className="flex ">
      <div
        className="flex h-full  cursor-pointer items-center justify-center rounded-xl border-dashed border-navy-700"
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        <button className="h-full w-full"> {content} </button>
      </div>
      <div className=''>
        {image ? <>
        <div className=" mx-4 items-center justify-center border-dotted border-2 self-center text-ellipsis border-sky-500 rounded-2xl    pt-3 pb-3 w-36 px-6 ml-2">
          <div className='justify-center items-center object-contain self-center '>
            <img src={image} alt='' className=' ml-3 object-scale-down w-16 h-10' />
            <div className="text-center mt-2"><button onClick={remove}>[remove]</button></div>
          </div>
        </div>

        </> : ''}
      </div>
    </div>
  );
};

export default DropZonefile2;
