import React from 'react';
import Card from '../card';
interface ExplainerTextProps {
  text: string;
  text2?: string;
  extra?: string;
}

export const ExplainerText = ({ text, text2, extra }: ExplainerTextProps) => {
  return (
    <Card
      extra={`container ml-0 mb-8 ${extra} my-3 rounded-2xl border-[1px] border-gray-100 px-4 py-4 pl-4 font-bold text-blue-900`}
    >
      <div className="text-[0.875rem]">
        {text}
        {text2 && <div className='mt-10'><br/>{text2}</div>}
      </div>
    </Card>
  );
};
