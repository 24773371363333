import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import axios from "axios";
import Card from "components/card";
import Spinner from "components/spinner/Spinner";
import { BusinessReportGoogleSheets } from "interfaces/business/BusinessReportGoogleSheets";
import { GoogleOAurhToken } from "interfaces/oauth/GoogleOAuthToken";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MdRefresh } from "react-icons/md";
import FormSelectField from "views/main/venture/add/components/FormSelectField";

interface GoogleSheetModalProps {
    open: boolean,
    googleOAuthToken: GoogleOAurhToken,
    googleSheet: BusinessReportGoogleSheets|null,
    setGoogleSheet: Dispatch<SetStateAction<BusinessReportGoogleSheets>> 
}

interface Spreadsheet {
    sheetId: string,
    name: string
}

interface Worksheet {
    worksheetId: number,
    name: string
}

interface Column {
    key: string,
    name: string
}

const GoogleSheetModal = ({ open, googleOAuthToken, googleSheet, setGoogleSheet }: GoogleSheetModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [spreadsheets, setSpreadSheets] = useState<Spreadsheet[]>([]);
    const [sheetId, setSheetId] = useState<string | null>(null);
    const [worksheets, setWorksheets] = useState<Worksheet[]>([]);
    const [worksheetId, setWorksheetId] = useState<number | null>(null);
    const [columns, setColumns] = useState<Column[]>([]);
    const [column, setColumn] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const renderSheets = () => {
        const options = spreadsheets.map((spreadsheet: Spreadsheet) => {
            return <option value={spreadsheet.sheetId}>{spreadsheet.name}</option>
        });

        options.unshift(<option value="0">Select</option>)

        return options;

    }
    const renderWorsksheet = () => {
        const options = worksheets.map((worksheet: Worksheet) => {
            return <option value={worksheet.worksheetId}>{worksheet.name}</option>
        });

        options.unshift(<option value="0">Select</option>)

        return options;
    }

    const renderColumn = () => {
        const options = columns.map((column: Column) => {
            return <option value={column.key}>{column.key} : {column.name}</option>
        });

        options.unshift(<option value="0">Select</option>)

        return options;
    }

    const loadSpreadSheets = async () => {
        if (googleOAuthToken) {
            setLoading(true);
            const url = process.env.REACT_APP_API_URL + '/google-sheets/{googleOAuthToken}/files'
                .replace('{googleOAuthToken}', googleOAuthToken.id.toString());
            await axios.get(url).then(r => {
                setSpreadSheets(r.data['hydra:member']);
                setLoading(false);
            });
        }
    }

    const createSpreadsheet = async () => {
        setLoading(true);
        const url = process.env.REACT_APP_API_URL + '/google-sheets/file';
        axios.post(url, { googleOAuthToken: "google_o_auth_tokens/" + googleOAuthToken.id })
            .then(r => {
                loadSpreadSheets();
                setLoading(false);
                setWorksheetId(0);
                setWorksheets([])
                setColumn(null);
                setColumns([]);
            });
    }

    const createFile = () => {
        createSpreadsheet();
    }

    const loadWorksheets = async () => {
        setLoading(true);
        const url = process.env.REACT_APP_API_URL + '/google-sheets/{googleOAuthToken}/file/{spreadsheet}/worksheet'
            .replace('{googleOAuthToken}', googleOAuthToken.id.toString())
            .replace('{spreadsheet}', sheetId);

        axios.get(url).then(r => {
            setWorksheets(r.data['hydra:member']);
            setLoading(false);
        });
    }

    const loadColumns = async () => {
        setLoading(true);
        const url = process.env.REACT_APP_API_URL + '/google-sheets/{googleOAuthToken}/file/{spreadsheet}/worksheet/{worksheetId}/columns'
            .replace('{googleOAuthToken}', googleOAuthToken.id.toString())
            .replace('{spreadsheet}', sheetId)
            .replace('{worksheetId}', worksheetId.toString());

        axios.get(url).then(r => {
            setColumns(r.data['hydra:member']);
            setLoading(false);
        });
    }

    const loadingElement = () => {
        if (loading) {
            return <div className="absolute w-full h-full pr-10 z-100000">
                <Spinner />
            </div>
        }
    }

    const saveGoogleSheets = () => {
        setGoogleSheet({
            ...googleSheet,
            googleOAuthToken: 'google_o_auth_tokens/'+googleOAuthToken.id.toString(),
            spreadsheetId: sheetId,
            worksheetId: worksheetId.toString(),
            column:column
        });
        onClose();
    }

    useEffect(() => {
        if (open) {
            loadSpreadSheets();
        }
    }, [open, googleOAuthToken])

    useEffect(() => {
        if (open) {
            onOpen();
        } else {
            onClose();
        }
    }, [open])

    useEffect(() => {
        if (sheetId && sheetId !== '0' && open === true) {
            loadWorksheets();
        }
    }, [sheetId, open])

    useEffect(() => {
        if (worksheetId && open === true) {
            loadColumns();
        }
    }, [worksheetId, open])

    useEffect(()=> {
        if(googleSheet !== null){
            setSheetId(googleSheet.spreadsheetId);
            setWorksheetId(parseInt(googleSheet.worksheetId));
            setColumn(googleSheet.column);
            console.log(googleSheet);
        }
    }, [googleSheet])

    return <>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" zIndex='1000' />
            <ModalContent containerProps={{
                zIndex: '2000',
            }}
                className="!z-[1002] !m-auto !w-max min-w-[550px] !max-w-[85%] md:top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w flex flex-col !z-[1004]">
                        {loadingElement()}
                        <div className={loading ? 'blur' : ''}>
                            <h1 className="mb-[20px] text-2xl font-bold">Search for Google Sheets Spreadsheet Row</h1>
                            <div className="mb-[20px]">
                                <div className="grid">
                                    <div className="col-span-2">
                                        <FormSelectField
                                            id="spreadsheet"
                                            label="Spreadsheet"
                                            value={sheetId}
                                            render={renderSheets}
                                            onChange={e => setSheetId(e.target.value)} />
                                    </div>
                                </div>

                                <div className="flex mt-2">
                                    <div className="flex-1 mr-2">
                                        <FormSelectField
                                            id="worksheet"
                                            label="Worksheet"
                                            value={worksheetId ? worksheetId.toString() : ''}
                                            render={renderWorsksheet}
                                            onChange={e => setWorksheetId(e.target.value)} />
                                    </div>
                                    <div className="flex-none pt-10">
                                        <button
                                            className="linear rounded-xl bg-brand-500 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                            onClick={loadWorksheets}
                                        >
                                            <MdRefresh />
                                        </button>
                                    </div>
                                </div>
                                <div className="flex mt-2">
                                    <div className="flex-1 mr-2">
                                        <FormSelectField
                                            id="column"
                                            label="Column"
                                            value={column ? column : ''}
                                            render={renderColumn}
                                            onChange={e => setColumn(e.target.value)} />
                                    </div>
                                    <div className="flex-none pt-10">
                                        <button
                                            className="linear rounded-xl bg-brand-500 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                            onClick={loadColumns}
                                        >
                                            <MdRefresh />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <div>
                                    <div className="flex gap-2">
                                        <button
                                            onClick={onClose}
                                            className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="linear text-blue-900 rounded-xl bg-gray-100 px-5 py-3 text-base font-medium transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                                            onClick={saveGoogleSheets}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                                <div className="flex-none">
                                    <p>Don't have a file?</p>
                                    <small><button className="underline" onClick={createFile}>Click here</button> to create a new Data Sheet</small>
                                </div>
                            </div>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
}

export default GoogleSheetModal;